import React, { useEffect, useState } from 'react'
import ReactDom from 'react-dom'
import MUIDataTable from 'mui-datatables'
import { Link } from "react-router-dom";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { Edit, AccessibilityNew, Add, Delete } from "@material-ui/icons";
import { useSelector, useDispatch } from "react-redux"
import GlobalLoader from "../layout/GlobalLoader"
import { getMuiTheme } from '../../Services/Common'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import StockMovModal from './StockMovModal'
import ItemsListModal from './ItemsListModal';
import ItemWise_PS_Modal from './ItemWise_PS_Modal'
import PurchaseSaleReport_Modal from './PurchaseSaleReport_Modal'
import PartywisePurSaleModal from './PartywisePurSaleModal'
import BufferStockModal from './BufferStockModal'
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import './ModalLinks.css';
import { Row, Col, Card } from "react-bootstrap"


function Reports() {
    const dispatch = useDispatch()
    const CompID = useSelector(({ login }) => login.UserCompany[0]?.CompID)
    document.title = "Stock Keeper / Reports"
    const [ModalTitle, setModalTitle] = useState("")
    const [loading, setLoading] = useState(false)
    const [Open, setOpen] = useState(false)
    const [Items_List_Modal, setItems_List_Modal] = useState(false)
    const [ItemwisepursaleModal, setItemwisepursaleModal] = useState(false)
    const [Purchase_SaleReport_Modal, setPurchase_SaleReport_Modal] = useState(false)
    const [PartyWise_PurSale_Modal, setPartyWise_PurSale_Modal] = useState(false)
    const [BufferStkReport_Modal, setBufferStkReport_Modal] = useState(false)
    const [GroupID, setGroupID] = useState(0)
    const [Group_Name, setGroup_Name] = useState('')
    const [Group_Type, setGroup_Type] = useState('')
    useEffect(() => {
        // setLoading(true)
    }, [])
    const showModal = () => {
        setOpen(true)
    }
    const closeModal = () => {
        setOpen(false)
    }
    const showItemsModal = () => {
        setItems_List_Modal(true)
    }
    const closeItenmsModal = () => {
        setItems_List_Modal(false)
    }
    const showItemWisePurSaleModal = () => {
        setItemwisepursaleModal(true)
    }
    const closeItemWisePurSaleModal = () => {
        setItemwisepursaleModal(false)
    }
    const showPurSaleRepModal = () => {
        setPurchase_SaleReport_Modal(true)
    }
    const closePurSaleRepModal = () => {
        setPurchase_SaleReport_Modal(false)
    }
    const showPartyWisePurSaleModal = () => {
        setPartyWise_PurSale_Modal(true)
    }
    const closePartyWisePurSaleModal = () => {
        setPartyWise_PurSale_Modal(false)
    }
    const showBufferStockModal = () => {
        setBufferStkReport_Modal(true)
    }
    const closeBufferStockModal = () => {
        setBufferStkReport_Modal(false)
    }
    // async function fetchData() {
    //     const response = await dispatch(SelectGroupData(CompID))
    //     setLoading(false)

    // }

    return ReactDom.createPortal(
        <div className="col-md-12">
            <ToastContainer />
            <div class="card" >
                <div class="card-body">
                    <div className='row' style={{ padding: '1%', marginLeft: "4px" }} >
                        <div className="col-md-6 col-lg-6 col-sm-6">
                            <h1>Reports</h1>
                        </div>
                    </div>
                    <div className='row' style={{ padding: '1%' }}>
                        {/* <div className="col-md-4 col-lg-4 col-sm-4"> */}
                        <Card className="popup-button col-md-4 col-lg-4 col-sm-4"
                            style={{ marginLeft: "20px", backgroundColor: "#293042", borderRadius: "20px", width: "280px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <h3 style={{ color: "white", paddingTop: "4px" }}>
                                <Link to="#"
                                    style={{ color: "white" }}
                                    onClick={e => {
                                        showModal()
                                    }}
                                >Stock Movement Report</Link>
                            </h3>
                        </Card>
                        {/* </div> */}
                        <div className="col-md-4 col-lg-4 col-sm-4">
                        </div>
                        <Card className="popup-button col-md-4 col-lg-4 col-sm-4"
                            style={{ marginLeft: "20px", backgroundColor: "#293042", borderRadius: "20px", width: "280px", display: "flex", alignItems: "center" }}>
                            <h3 style={{ color: "white", paddingTop: "4px" }}>
                                <Link to="#"
                                    style={{ color: "white" }}
                                    onClick={e => {
                                        showItemsModal()
                                    }}
                                >Items List Report</Link>
                            </h3>
                        </Card>
                    </div>
                    <div className='row' style={{ padding: '1%' }}>
                        <Card className="popup-button col-md-4 col-lg-4 col-sm-4"
                            style={{ marginLeft: "20px", backgroundColor: "#293042", borderRadius: "20px", width: "280px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <h3 style={{ color: "white", paddingTop: "4px" }}>
                                <Link to="#"
                                    style={{ color: "white" }}
                                    onClick={e => {
                                        showPurSaleRepModal()
                                        setModalTitle("Purchase Report")
                                    }}
                                >Purchase Report</Link>
                            </h3>
                        </Card>
                        <div className="col-md-4 col-lg-4 col-sm-4">
                        </div>
                        <Card className="popup-button col-md-4 col-lg-4 col-sm-4"
                            style={{ marginLeft: "20px", backgroundColor: "#293042", borderRadius: "20px", width: "280px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <h3 style={{ color: "white", paddingTop: "4px" }}>
                                <Link to="#"
                                    style={{ color: "white" }}
                                    onClick={e => {
                                        showPurSaleRepModal()
                                        setModalTitle("Consumption Report")
                                    }}
                                >Consumption Report</Link>
                            </h3>
                        </Card>
                    </div>
                    <div className='row' style={{ padding: '1%' }}>
                        <Card className="popup-button col-md-4 col-lg-4 col-sm-4"
                            style={{ marginLeft: "20px", backgroundColor: "#293042", borderRadius: "20px", width: "280px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <h3 style={{ color: "white", paddingTop: "4px" }}>
                                <Link to="#"
                                    style={{ color: "white" }}
                                    onClick={e => {
                                        showPartyWisePurSaleModal()
                                        setModalTitle("Party Wise Purchases")
                                    }}
                                >Party Wise Purchases</Link>
                            </h3>
                        </Card>
                        <div className="col-md-4 col-lg-4 col-sm-4">
                        </div>
                        <Card className="popup-button col-md-4 col-lg-4 col-sm-4"
                            style={{ marginLeft: "20px", backgroundColor: "#293042", borderRadius: "20px", width: "280px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <h3 style={{ color: "white", paddingTop: "4px" }}>
                                <Link to="#"
                                    style={{ color: "white" }}
                                    onClick={e => {
                                        showPartyWisePurSaleModal()
                                        setModalTitle("Dept. Wise Consumption")
                                    }}
                                >Dept. Wise Consumption</Link>
                            </h3>
                        </Card>
                    </div>
                    <div className='row' style={{ padding: '1%' }}>
                        <Card className="popup-button col-md-4 col-lg-4 col-sm-4"
                            style={{ marginLeft: "20px", backgroundColor: "#293042", borderRadius: "20px", width: "280px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <h3 style={{ color: "white", paddingTop: "4px" }}>
                                <Link to="#"
                                    style={{ color: "white" }}
                                    onClick={e => {
                                        showItemWisePurSaleModal()
                                        setModalTitle("Item Wise Purchases")
                                    }}
                                >Item Wise Purchases</Link>
                            </h3>
                        </Card>
                        <div className="col-md-4 col-lg-4 col-sm-4">
                        </div>
                        <Card className="popup-button col-md-4 col-lg-4 col-sm-4"
                            style={{ marginLeft: "20px", backgroundColor: "#293042", borderRadius: "20px", width: "280px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <h3 style={{ color: "white", paddingTop: "4px" }}>
                                <Link to="#"
                                    style={{ color: "white" }}
                                    onClick={e => {
                                        showItemWisePurSaleModal()
                                        setModalTitle("Item Wise Consumption")
                                    }}
                                >Item Wise Consumption</Link>
                            </h3>
                        </Card>
                    </div>
                    <div className='row' style={{ padding: '1%' }}>
                        <Card className="popup-button col-md-4 col-lg-4 col-sm-4"
                            style={{ marginLeft: "20px", backgroundColor: "#293042", borderRadius: "20px", width: "280px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <h3 style={{ color: "white", paddingTop: "4px" }}>
                                <Link to="#"
                                    style={{ color: "white" }}
                                    onClick={e => {
                                        // window.open("/BufferStockReport", "_blank")
                                        showBufferStockModal()
                                        // setModalTitle("Buffer Stock Report")
                                    }}
                                >Minimum Stock Report</Link>
                            </h3>
                        </Card>
                        <div className="col-md-4 col-lg-4 col-sm-4">
                        </div>
                    </div>
                    <GlobalLoader
                        open={loading}
                    />
                    <StockMovModal
                        handleClose={closeModal}
                        open={Open}
                    // GroupID={GroupID}
                    // fetchData={fetchData}
                    // type={Group_Type}
                    // name={Group_Name}
                    />
                    <ItemsListModal
                        handleClose={closeItenmsModal}
                        open={Items_List_Modal}
                    // GroupID={GroupID}
                    // fetchData={fetchData}
                    // type={Group_Type}
                    // name={Group_Name}
                    />
                    <ItemWise_PS_Modal
                        handleClose={closeItemWisePurSaleModal}
                        open={ItemwisepursaleModal}
                        Modal_Title={ModalTitle}
                    // GroupID={GroupID}
                    // fetchData={fetchData}
                    // type={Group_Type}
                    // name={Group_Name}
                    />
                    <PurchaseSaleReport_Modal
                        handleClose={closePurSaleRepModal}
                        open={Purchase_SaleReport_Modal}
                        Modal_Title={ModalTitle}
                    // GroupID={GroupID}
                    // fetchData={fetchData}
                    // type={Group_Type}
                    // name={Group_Name}
                    />
                    <PartywisePurSaleModal
                        handleClose={closePartyWisePurSaleModal}
                        open={PartyWise_PurSale_Modal}
                        Modal_Title={ModalTitle}
                    // GroupID={GroupID}
                    // fetchData={fetchData}
                    // type={Group_Type}
                    // name={Group_Name}
                    />
                    <BufferStockModal
                        handleClose={closeBufferStockModal}
                        open={BufferStkReport_Modal}
                    // Modal_Title={ModalTitle}
                    // GroupID={GroupID}
                    // fetchData={fetchData}
                    // type={Group_Type}
                    // name={Group_Name}
                    />
                </div>
            </div>
        </div>
        ,
        document.getElementById('content')
    )
}

export default Reports