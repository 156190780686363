import { REACT_BASE_URL } from "./Common"
import axios from "axios"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export const SelectParty = async (CompID, GroupID) => {
    const response = await axios.get(`${REACT_BASE_URL}/Parties/SelectParty/${CompID}/${GroupID}`)
    return response;
}

export const SelectGroupParty = async (CompID, GroupType, IsActive) => {
    const response = await axios.get(`${REACT_BASE_URL}/Parties/SelectGroupParty/${CompID}/${GroupType}/${IsActive}`)
    return response;
}

export const SaveParty = async (PartyData, IsAddNew) => {
    const response = await axios.post(`${REACT_BASE_URL}/Parties/SaveParty`, { PartyData, IsAddNew })
    return response;
}

// export const DeleteItemCat = async (CatID) => {
//     const response = await axios.delete(`${REACT_BASE_URL}/ItemCategory/DeleteItemCat/${CatID}`)
//     return response;
// }

