import React, { useEffect, useState } from 'react'
import ReactDom from 'react-dom'
import MUIDataTable from 'mui-datatables'
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap"
import { MuiThemeProvider } from "@material-ui/core/styles";
import { Edit, AccessibilityNew, Add, Delete } from "@material-ui/icons";
import { TextField, FormControlLabel, Checkbox, Card } from '@material-ui/core'
import { useSelector, useDispatch } from "react-redux"
import GlobalLoader from "../layout/GlobalLoader"
import { getMuiTheme } from '../../Services/Common'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PartyModal from './PartyModal'
import { SelectPartyData } from './PartiesSlices'
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import Autocomplete from '@mui/material/Autocomplete';
import { SelectGroups } from "../../Services/GroupsAPI"
import { SelectParty } from "../../Services/PartiesAPI"
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from "@material-ui/core/InputAdornment";

function Party() {
    const dispatch = useDispatch()
    const CompID = useSelector(({ login }) => login.UserCompany[0]?.CompID)
    const UserType = useSelector(({ login }) => login?.user[0]?.UserType)
    document.title = "Stock Keeper / Parties"
    const [_PartiesList, set_PartiesList] = useState([])
    const [loading, setLoading] = useState(false)
    const [Open, setOpen] = useState(false)
    const [PartyID, setPartyID] = useState(0)
    const [Party_Name, setParty_Name] = useState('')
    const [Phone_Num, setPhone_Num] = useState('')
    const [E_mail, setE_mail] = useState('')
    const [Addrress, setAddrress] = useState('')
    const [Is_active, setIs_active] = useState(true)
    const [GroupList, setGroupList] = useState([])
    const [SelectedGroup, setSelectedGroup] = useState(null)
    const [ErrorMsg, setErrorMsg] = useState({ TypeError: '' })
    const [query, setQuery] = useState('');
    useEffect(() => {
        setLoading(true)
        fetchGroups()
    }, [])
    const validate = () => {
        if (!SelectedGroup) {
            setErrorMsg({ TypeError: 'Type Required' })
            document.getElementById('disable-clearable').focus()
            return false;
        }
        setErrorMsg({ TypeError: '' })
        return true;
    }
    const showModal = () => {
        if (validate()) {
            setOpen(true)
        }

    }
    const handleInputChange = (event) => {
        setQuery(event.target.value);
    };

    const closeModal = () => {
        setOpen(false)
    }
    async function fetchGroups() {
        const response = await SelectGroups(CompID)
        if (response.data.Data.length > 0) {
            setSelectedGroup(response.data.Data[0])
            fetchData(response.data.Data[0].GroupID)
        }
        setGroupList(response.data.Data)
        setLoading(false)
    }
    async function fetchData(GroupID) {
        const response = await SelectParty(CompID, GroupID)
        set_PartiesList(response.data.Data)
        setLoading(false)
    }
    // const handleDelete = (CatID) => {
    //     Swal.fire({
    //         title: 'Are you sure?',
    //         text: 'You will not be able to recover this data!',
    //         icon: 'warning',
    //         showCancelButton: true,
    //         confirmButtonColor: '#3085d6',
    //         cancelButtonColor: '#d33',
    //         confirmButtonText: 'Yes, Delete This Item Cat!'
    //     }).then(async (result) => {
    //         if (result.isConfirmed) {
    //             const response = await DeleteItemCat(CatID)
    //             if (response.status === 200) {
    //                 if (response.data.status) {
    //                     Swal.fire(

    //                         'Deleted!',
    //                         'Your ItemCat has been deleted.',
    //                         'success'
    //                     );
    //                     fetchData();
    //                 }
    //                 else {
    //                     Swal.fire(

    //                         'OOPPSS!',
    //                         response.data.message,
    //                         'info'
    //                     );
    //                     fetchData();
    //                 }

    //             }

    //         }
    //     });
    // };

    const columns = [
        {
            name: "row_number",
            label: "#",
            options: {
                filter: true,
                sort: true,
                customHeadLabelRender: (columnMeta) => {
                    return (
                        <div style={{ fontSize: "12px" }} >{columnMeta.label}</div>
                    )
                },
            }
        },
        {
            name: "PartyName",
            label: "Name",
            options: {
                filter: true,
                sort: true,
                customHeadLabelRender: (columnMeta) => {
                    return (
                        <div style={{ fontSize: "12px" }} >{columnMeta.label}</div>
                    )
                },
            }
        },
        {
            name: "PhoneNo",
            label: "Phone No",
            options: {
                filter: true,
                sort: true,
                customHeadLabelRender: (columnMeta) => {
                    return (
                        <div style={{ fontSize: "12px" }} >{columnMeta.label}</div>
                    )
                },
            }
        },
        {
            name: "Address",
            label: "Address",
            options: {
                filter: true,
                sort: true,
                customHeadLabelRender: (columnMeta) => {
                    return (
                        <div style={{ fontSize: "12px" }} >{columnMeta.label}</div>
                    )
                },
            }
        },
        {
            name: "Email",
            label: "Email",
            options: {
                filter: true,
                sort: true,
                customHeadLabelRender: (columnMeta) => {
                    return (
                        <div style={{ fontSize: "12px" }} >{columnMeta.label}</div>
                    )
                },
            }
        },
        {
            name: "IsActive",
            label: "IsActive",
            options: {
                filter: true,
                sort: true,
                customHeadLabelRender: (columnMeta) => {
                    return (
                        <div style={{ fontSize: "12px" }} >{columnMeta.label}</div>
                    )
                },
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Checkbox
                            style={{ color: '#293042' }}
                            checked={value}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    )
                },
            }
        },
        {
            name: "Action",
            label: "Action",
            options: {
                filter: false,
                sort: false,
                customHeadLabelRender: (columnMeta) => {
                    return (
                        <div style={{ fontSize: "12px" }} >{columnMeta.name}</div>
                    )
                },
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    if (_PartiesList[dataIndex] != null)
                        return (
                            <>
                                {UserType === 'Admin' ? (
                                    <Link
                                        to={"#"}
                                        onClick={e => {
                                            setPartyID(_PartiesList[dataIndex].PartyID)
                                            setParty_Name(_PartiesList[dataIndex].PartyName)
                                            setPhone_Num(_PartiesList[dataIndex].PhoneNo)
                                            setE_mail(_PartiesList[dataIndex].Email)
                                            setAddrress(_PartiesList[dataIndex].Address)
                                            setIs_active(_PartiesList[dataIndex].IsActive)
                                            showModal()
                                        }}
                                    >
                                        <Edit className="IconsColor popup-button" />
                                    </Link>
                                ) : (
                                    <Link
                                        to={"#"}
                                        onClick={e => {
                                            Swal.fire(
                                                'OOPS!',
                                                `Only Admin can Edit this Party!`,
                                                'Info'
                                            )
                                        }}
                                    >
                                        <Edit className="IconsColor popup-button" />
                                    </Link>
                                )
                                }
                            </>
                        );
                }
            }
        },
    ];

    return ReactDom.createPortal(
        <div className="col-md-12">
            <ToastContainer />
            <div class="card" >
                <div class="card-body">
                    <div className='row' style={{ padding: '1%' }} >
                        <div className="col-md-6 col-lg-6 col-sm-6">
                            <h1>Parties</h1>
                        </div>
                        <div className="col-md-6 col-lg-6 col-sm-6" style={{ textAlign: 'right' }} >
                            {UserType === 'Admin' ? (
                                <button
                                    // style={{ backgroundColor:"#293042" }}
                                    type="button"
                                    onClick={e => {
                                        setPartyID(0)
                                        showModal()
                                    }}
                                    className="ThemeHeader popup-button"
                                > <Add /> </button>
                            ) : (
                                <button
                                    // style={{ backgroundColor:"#293042" }}
                                    type="button"
                                    onClick={e => {
                                        Swal.fire(
                                            'OOPS!',
                                            `Only Admin can Add New Party!`,
                                            'Info'
                                        )
                                    }}
                                    className="ThemeHeader popup-button"
                                > <Add /> </button>
                            )}
                        </div>
                    </div>
                    <div className='row' style={{ padding: '1%' }}>
                        <div className="col-md-4 col-lg-4 col-sm-4">
                            <Autocomplete
                                id="disable-clearable"
                                disableClearable
                                options={GroupList}
                                getOptionLabel={(option) => option.GroupName}
                                value={SelectedGroup}
                                onChange={(e, value) => {
                                    setLoading(true)
                                    setSelectedGroup(value)
                                    fetchData(value.GroupID)
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} label="Type" variant="standard" />
                                )}
                                fullWidth
                            />
                            <span style={{ color: "red" }}>{ErrorMsg.TypeError}</span>
                        </div>
                    </div>
                    <div>
                        {/* <TextField
                            placeholder="Search"
                            type="search"
                            variant="outlined"
                            fullWidth
                            size="small"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        /> */}
                    </div>
                    <div className='row' style={{ paddingTop: '1%' }}>
                        <div className="col-md-12 col-lg-12 col-sm-12">
                            <MuiThemeProvider theme={getMuiTheme()}>
                                <MUIDataTable
                                    style={{ size: "200px" }}
                                    data={_PartiesList}
                                    columns={columns}
                                    options={{
                                        selectableRows: false,
                                        search: true,
                                        download: false,
                                        print: false,
                                        viewColumns: false,
                                        filter: true,
                                        selectableRows: false,
                                        selection: true,
                                        toolbar: false,
                                        paging: false,
                                        headerStyle: {
                                            fontSize: 12
                                        },
                                    }
                                    }
                                />
                            </MuiThemeProvider>
                        </div>
                    </div>
                    <GlobalLoader
                        open={loading}
                    />
                    <PartyModal
                        handleClose={closeModal}
                        open={Open}
                        PartyID={PartyID}
                        fetchData={fetchData}
                        SelectedGroup={SelectedGroup}
                        partyname={Party_Name}
                        phno={Phone_Num}
                        email={E_mail}
                        addr={Addrress}
                        isactive={Is_active}
                    />
                </div>
            </div>
        </div>
        ,
        document.getElementById('content')
    )
}

export default Party