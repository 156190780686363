import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { SelectItemCat } from '../../Services/ItemCatAPI'


export const SelectItemCatData = createAsyncThunk('itemcat/SelectItemCatData', async (CompID) => {
    const response = await SelectItemCat(CompID)
    return response.data
})

const initialState = {
    loading: false,
    ItemCatData: []
}

const itemcatSlice = createSlice({
    name: 'itemcat',
    initialState,
    reducers: {},
    extraReducers: {
        [SelectItemCatData.fulfilled]: (state, action) => {
            state.ItemCatData = action.payload.Data
        }
    }
})

export default itemcatSlice.reducer;