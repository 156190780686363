import React, { useEffect, useState } from 'react'
import ReactDom from "react-dom"
import { getMuiTheme, getCurrentDate, show_hidden_divs } from '../../Services/Common'
import { Row, Col, Card } from "react-bootstrap"
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import { TextField, FormControlLabel, Checkbox } from '@material-ui/core'
import { DashboardPurchaseTotal } from "../../Services/TransactionAPI"
import { separateNumber } from './../ThousandSeparator'
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import InventoryIcon from '@mui/icons-material/Inventory';
import { SelectItems } from "../../Services/ItemAPI"
import { LastPurchaseRate, TopPurSaleItem } from '../../Services/TransactionAPI'
import Autocomplete from '@mui/material/Autocomplete';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import CategoryIcon from '@mui/icons-material/Category';
// import './Live.css'

function Dashboard() {
  const Comp_Abbr = useSelector(({ login }) => login?.UserCompany[0]?.Abbr)
  const CompID = useSelector(({ login }) => login.UserCompany[0]?.CompID)
  const UserName = useSelector(({ login }) => login?.user[0]?.UserName)
  const [FromDate, setFromDate] = useState(getFirstDayOfCurrentMonth())
  const [ToDate, setToDate] = useState(getCurrentDate)
  const [PurchasesTotal, setPurchasesTotal] = useState(0)
  const [SalesTotal, setSalesTotal] = useState(0)
  const [ItemsList, setItemsList] = useState([])
  const [SelectedItem, setSelectedItem] = useState(null)
  const [LastPurchaseRateParty, setLastPurchaseRateParty] = useState("")
  const [LastSalesRateParty, setLastSalesRateParty] = useState("")
  const [TopPurItem, setTopPurItem] = useState([])
  const [TopSoldItem, setTopSoldItem] = useState([])
  const [Stock_Quantity, setStock_Quantity] = useState(0)
  const [DepartmentName, setDepartmentName] = useState("")
  const [Status, setStatus] = useState("")
  const [CategoryName, setCategoryName] = useState("")

  useEffect(() => {
    show_hidden_divs()
    fetchAPIData(FromDate, ToDate)
    fetchItems()
    fetchTopPurSoldItem()
  }, [])
  async function fetchAPIData(FromDate, ToDate) {
    const responsePUr = await DashboardPurchaseTotal(CompID, "Stock In", FromDate, ToDate)
    const responseSale = await DashboardPurchaseTotal(CompID, "Stock Out", FromDate, ToDate)
    const _PurAmount = (separateNumber(responsePUr.data.Data[0].Amount))
    setPurchasesTotal(_PurAmount)
    const _SaleAmount = (separateNumber(responseSale.data.Data[0].Amount))
    setSalesTotal(_SaleAmount)
  }

  async function fetchTopPurSoldItem() {
    debugger
    const responseTopPur = await TopPurSaleItem(CompID, "Stock In")
    const responseTopSale = await TopPurSaleItem(CompID, "Stock Out")
    const _TopPurItem = responseTopPur.data.Data
    setTopPurItem(_TopPurItem)
    const _TopSaleItem = responseTopSale.data.Data
    setTopSoldItem(_TopSaleItem)
  }

  function getFirstDayOfCurrentMonth() {
    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const year = firstDayOfMonth.getFullYear();
    const month = String(firstDayOfMonth.getMonth() + 1).padStart(2, '0');
    const day = String(firstDayOfMonth.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
  async function fetchLastPurRate(ItemID) {
    debugger
    // const TrType = FormName === "Purchase" ? "Stock In" : "Stock Out"
    const responseLPR = await LastPurchaseRate(CompID, "Stock In", ItemID)
    const responseLSR = await LastPurchaseRate(CompID, "Stock Out", ItemID)
    if (responseLPR.data && responseLPR.data.Data && responseLPR.data.Data.length > 0) {
      setLastPurchaseRateParty("Rate: " + responseLPR.data.Data[0].LastPurchaseRate + "/-" + "   " + "From: " + responseLPR.data.Data[0].PartyName)
      setStock_Quantity(responseLPR.data.Data[0].StockQty)
     
    }
    else {
      setLastPurchaseRateParty(" ")
      setStock_Quantity(0)
     
    }
    if (responseLSR.data && responseLSR.data.Data && responseLSR.data.Data.length > 0) {
      setLastSalesRateParty(responseLSR.data.Data[0].LastQty)
    }
    // for last sale rate
    // if (responseLSR.data && responseLSR.data.Data && responseLSR.data.Data.length > 0) {
    //   setLastSalesRateParty("Rate: " + responseLSR.data.Data[0].LastPurchaseRate + "/-" + "   " + "To: " + responseLSR.data.Data[0].PartyName)
    // }
    else {
      setLastSalesRateParty(" Not Consumed ")
    }
    if (responseLPR.data.item[0].StockQty >= responseLPR.data.item[0].BufferStock) {
      setStatus("OK")
    }
    else {
      setStatus("Needs to be Reordered")
    }

  }
  async function fetchItems() {
    const response = await SelectItems(CompID, 0, 1)
    setItemsList(response.data.Data)
    if (response.data.Data.length > 0) {
      setSelectedItem(response.data.Data[0]); // Automatically select the first item
      setDepartmentName(response.data.Data[0].PartyName)
      setCategoryName(response.data.Data[0].CatName)
      fetchLastPurRate(response.data.Data[0].ItemID); // Fetch last purchase rate for the first item
    }
  }

  return ReactDom.createPortal(
    <div className="card" style={{ marginBottom: "2%" }}>
      <div className="card-body">

        <h1 style={{ marginBottom: "1%", marginLeft: "50px" }}></h1>
        <Card style={{ backgroundColor: "black", borderRadius: "20px", border: "5px solid black", marginRight: "35px", marginLeft: "40px" }}>
          <h1 style={{ textAlign: "center" }}>  </h1>
          <div>
            <Row style={{}}>
              <Col>
                <h1 className="popup-label" style={{ fontSize: "24px", textAlign: "center", color: "white" }}>{UserName}'s Dashboard</h1>
              </Col>
            </Row>
          </div>
        </Card>
        <Row style={{ marginLeft: "3%", marginRight: "3%" }}>
          <Col sm={4} md={4} lg={4} xl={4}>
            <Card style={{ backgroundColor: "white", borderRadius: "20px", border: "5px solid #293042" }}>
              <h1 style={{ textAlign: "center" }}>  </h1>
              <Row style={{ marginTop: "5%" }}>
                <Col className="col-md-6 col-lg-6 col-sm-6" style={{ paddingLeft: "30px" }}>
                  <TextField
                    name="FromDate"
                    id="FromDate"
                    label="From Date"
                    type="date"
                    value={FromDate}
                    onChange={e => {
                      fetchAPIData(e.target.value, ToDate)
                      setFromDate(e.target.value)
                    }}
                    fullWidth

                  />
                </Col>
                <Col className="col-md-6 col-lg-6 col-sm-6" style={{ paddingRight: "30px" }} >
                  <TextField
                    name="ToDate"
                    id="ToDate"
                    label="To Date"
                    type="date"
                    value={ToDate}
                    onChange={e => {
                      fetchAPIData(FromDate, e.target.value)
                      setToDate(e.target.value)
                    }}
                    fullWidth

                  />
                </Col>
              </Row>
              <div>
                <Row style={{ marginTop: "15%" }}>
                  <Link
                    style={{ textAlign: "center" }}
                  >
                    <InventoryIcon style={{ color: "blue", textAlign: "center" }} />
                  </Link>
                  <Col style={{ marginTop: "1%" }}>

                    <h1 className="popup-label" style={{ textAlign: "center", fontSize: "30px" }}>{PurchasesTotal}</h1>
                  </Col>
                </Row>
                <Row style={{ marginBottom: "18%" }}>
                  <Col>
                    <h3 style={{ textAlign: "center", color: "blue" }}>Purchases</h3>
                  </Col>
                </Row>
                <Row style={{ marginTop: "15%" }}>
                  <Link
                    style={{ textAlign: "center" }}
                  >
                    <PointOfSaleIcon style={{ color: "green", textAlign: "center" }} />
                  </Link>
                  <Col style={{ marginTop: "1%" }}>

                    <h1 className="popup-label" style={{ textAlign: "center", fontSize: "30px" }}>{SalesTotal}</h1>
                  </Col>
                </Row>
                <Row style={{ marginBottom: "18%" }}>
                  <Col>
                    <h3 style={{ textAlign: "center", color: "green" }}>Consumption</h3>
                  </Col>
                </Row>
              </div>
            </Card>
          </Col>
          <Col sm={4} md={4} lg={4} xl={4}>
            <Card style={{ backgroundColor: "white", borderRadius: "20px", border: "5px solid #293042" }}>
              <h1 style={{ textAlign: "center" }}>  </h1>
              <Row style={{ marginBottom: "0.5%" }}>
                <Col>
                  <h1 style={{ textAlign: "center", color: "black" }}>Check Last Rates</h1>
                </Col>
              </Row>
              <Row style={{ marginTop: "5%" }}>
                <Col className="col-md-10 col-lg-10 col-sm-10" style={{ marginLeft: "30px" }}>
                  <Autocomplete
                    style={{ backgroundColor: "transparent" }}
                    id="disable-clearable"
                    disableClearable
                    options={ItemsList}
                    getOptionLabel={(option) => option.ItemName}
                    value={SelectedItem}
                    onChange={(e, value) => {
                      debugger
                      fetchLastPurRate(value.ItemID)
                      setSelectedItem(value)
                      setDepartmentName(value.PartyName)
                      setCategoryName(value.CatName)
                      // setRate(parseFloat(value.Rate))
                      // CalculateAmount(Qty, value.Rate)
                      // setErrorMsg({ ...ErrorMsg, ItemError: '' })
                      // setStkQty(value.StockQty)
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Select Item" variant="standard" />
                    )}
                    fullWidth
                  />
                </Col>

              </Row>
              {/* <Col style={{ marginTop: "2%" }}>
                <h1 style={{ textAlign: "center", color: "black" }}>Stock Quantity : {Stock_Quantity}</h1>
              </Col> */}
              <div>
                <Row style={{ marginTop: "10%" }}>
                  <Col>
                    <h3 style={{ textAlign: "center", color: "green" }}>Department</h3>
                  </Col>
                </Row>
                <Row style={{ marginBottom: "1%" }}>
                  {/* <Link
                    style={{ textAlign: "center" }}
                  >
                    <CategoryIcon style={{ color: "green", textAlign: "center" }} />
                  </Link> */}
                  <Col style={{ marginTop: "1%" }}>

                    <h1 style={{ textAlign: "center", fontSize: "13px" }}>{DepartmentName}</h1>
                  </Col>
                </Row>
                <Row style={{ marginTop: "1%" }}>
                  <Col>
                    <h3 style={{ textAlign: "center", color: "green" }}>Category</h3>
                  </Col>
                </Row>
                <Row style={{ marginBottom: "1%" }}>
                  {/* <Link
                    style={{ textAlign: "center" }}
                  >
                    <CategoryIcon style={{ color: "green", textAlign: "center" }} />
                  </Link> */}
                  <Col style={{ marginTop: "1%" }}>

                    <h1 style={{ textAlign: "center", fontSize: "13px" }}>{CategoryName}</h1>
                  </Col>
                </Row>
                <Row style={{ marginTop: "1%" }}>
                  <Col>
                    <h3 style={{ textAlign: "center", color: "green" }}>Current Stock</h3>
                  </Col>
                </Row>
                <Row style={{ marginBottom: "1%" }}>
                  {/* <Link
                    style={{ textAlign: "center" }}
                  >
                    <CategoryIcon style={{ color: "blue", textAlign: "center" }} />
                  </Link> */}
                  <Col style={{ marginTop: "1%" }}>

                    <h1 style={{ textAlign: "center", fontSize: "18px" }}>{Stock_Quantity}</h1>
                  </Col>
                </Row>
                {/* <Row style={{ marginTop: "1%" }}>
                  <Col>
                    <h3 style={{ textAlign: "center", color: "green" }}>Last Consumption Quantity</h3>
                  </Col>
                </Row>
                <Row style={{ marginBottom: "1%" }}>
                  <Col style={{ marginTop: "1%" }}>

                    <h1 style={{ textAlign: "center", fontSize: "18px" }}>{LastSalesRateParty}</h1>
                  </Col>
                </Row> */}
                <Row style={{ marginTop: "1%" }}>
                  <Col>
                    <h3 style={{ textAlign: "center", color: "green" }}>Last Purchase Rate</h3>
                  </Col>
                </Row>
                <Row style={{ marginBottom: "1%" }}>
                  {/* <Link
                    style={{ textAlign: "center" }}
                  >
                    <LocalAtmIcon style={{ color: "green", textAlign: "center" }} />
                  </Link> */}
                  <Col style={{ marginTop: "1%" }}>

                    <h1 style={{ textAlign: "center", fontSize: "13px" }}>{LastPurchaseRateParty}</h1>
                  </Col>
                </Row>



                <Row style={{ marginBottom: "1%" }}>
                  <Col style={{ marginTop: "1%" }}>
                    <h3 style={{ textAlign: "center", fontSize: "15px", color: Status === "OK" ? "green" : "red" }}>
                      Status : {Status}
                    </h3>
                  </Col>
                </Row>


                {/* <Row style={{ marginTop: "10%" }}>
                  <Link
                    style={{ textAlign: "center" }}
                  >
                    <MonetizationOnIcon style={{ color: "green", textAlign: "center" }} />
                  </Link>
                  <Col style={{ marginTop: "1%" }}>

                    <h1 style={{ textAlign: "center", fontSize: "13px" }}>{LastSalesRateParty}</h1>
                  </Col>
                </Row>
                <Row style={{ marginBottom: "18%" }}>
                  <Col>
                    <h3 style={{ textAlign: "center", color: "green" }}>Last Rate</h3>
                  </Col>
                </Row> */}
              </div>
            </Card>
          </Col>
          <Col sm={4} md={4} lg={4} xl={4}>
            <Row>
              <Card style={{ backgroundColor: "#66CC99", borderRadius: "20px", border: "5px solid #293042" }}>
                <h1 style={{ textAlign: "center" }}>  </h1>
                <div>
                  <Row style={{}}>
                    <Col>
                      <h2 className="popup-label" style={{ textAlign: "center", color: "black", textDecoration: "underline" }}>Top Purchased Items</h2>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "7%" }}>
                    <Col style={{ marginTop: "1%" }}>
                      {
                        TopPurItem.map(value => (
                          // <h3 style={{ textAlign: "left", fontSize: "18px", color: "white" }}>{value.ItemName}    {value.TotalQuantity}</h3>
                          <Row>
                            <Col sm={8} md={8} lg={8} xl={8} style={{ textAlign: "left", fontSize: "17px", color: "black" }}>{value.ItemName} </Col>
                            <Col sm={4} md={4} lg={4} xl={4} style={{ textAlign: "right", fontSize: "17px", color: "black" }}> {value.TotalQuantity}</Col>
                          </Row>
                        ))
                      }

                    </Col>
                  </Row>

                </div>
              </Card>
            </Row>
            <Row>
              <Card style={{ backgroundColor: "#d1e7dd", borderRadius: "20px", border: "5px solid #293042" }}>
                <h1 style={{ textAlign: "center" }}>  </h1>
                <div>
                  <Row style={{}}>
                    <Col>
                      <h2 className="popup-label" style={{ textAlign: "center", color: "black", textDecoration: "underline" }}>Top Consumed Items</h2>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "7%" }}>
                    <Col style={{ marginTop: "1%" }}>
                      {
                        TopSoldItem.map(value => (
                          <Row>
                            <Col sm={8} md={8} lg={8} xl={8} style={{ textAlign: "left", fontSize: "17px", color: "black" }}>{value.ItemName} </Col>
                            <Col sm={4} md={4} lg={4} xl={4} style={{ textAlign: "right", fontSize: "17px", color: "black" }}> {value.TotalQuantity}</Col>
                          </Row>
                        ))
                      }

                    </Col>
                  </Row>

                </div>
              </Card>
            </Row>
            <Row>
              <Card style={{ backgroundColor: "#6c757d", borderRadius: "20px", border: "5px solid black" }}>
                <h1 style={{ textAlign: "center" }}>  </h1>
                <div>
                  <Row style={{}}>
                    <Col>
                      <h1 className="popup-label" style={{ fontSize: "24px", textAlign: "center", color: "white" }}>{ItemsList.length} Items</h1>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Row>
            {/* <button className="live-button">
              <span className="dot"></span>
              Live
            </button> */}

          </Col>
        </Row>
      </div>

    </div>, document.getElementById('content')
  )
}

export default Dashboard