import React, { useEffect, useState } from 'react'
import ReactDom from 'react-dom'
import { Checkbox, TextField } from '@material-ui/core'
import { Save, Cancel } from '@material-ui/icons'
import MUIDataTable from 'mui-datatables'
import { Link } from "react-router-dom";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { AccessibilityNew } from "@material-ui/icons";
import { useSelector, useDispatch } from "react-redux"
import { get_Role_List, get_role_modules, save_role, save_role_modules, setModuleId } from './UsersSlice'
import GlobalLoader from "../layout/GlobalLoader"
import { getMuiTheme } from '../../Services/Common'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function UserAccess() {
    const dispatch = useDispatch();
    const _RoleModulesList = useSelector(({ UsersSlice }) => UsersSlice.RoleModulesList)
    const loading = useSelector(({ UsersSlice }) => UsersSlice.loading)
    const Role = useSelector(({ UsersSlice }) => UsersSlice.Role)
    const [RoleModulesList, setRoleModulesList] = useState(_RoleModulesList)
    const [btnLoader, setBtnLoader] = useState(false)
    useEffect(() => {
        onLoad();
    }, [Role])

    useEffect(() => {
        setRoleModulesList(_RoleModulesList)
    }, [_RoleModulesList])

    const onLoad = async () => {
        if (Role.RoleId) {
            await dispatch(get_role_modules(Role.RoleId))
        }
    }

    document.title = "OE Squills / Users Access"
    const columns = [
        {
            name: "rno",
            label: "#",
            options: {
                filter: true,
                sort: true,
                customHeadLabelRender: (columnMeta) => {
                    return (
                        <div style={{ fontSize: "12px" }} >{columnMeta.label}</div>
                    )
                },
            }
        },
        {
            name: "ModuleName",
            label: "Module Name",
            options: {
                filter: true,
                sort: true,
                customHeadLabelRender: (columnMeta) => {
                    return (
                        <div style={{ fontSize: "12px" }} >{columnMeta.label}</div>
                    )
                },
            }
        },
        {
            name: "IsAccess",
            label: "Access",
            options: {
                filter: true,
                sort: true,
                customHeadLabelRender: (columnMeta) => {
                    return (
                        <div style={{ fontSize: "12px" }} >{columnMeta.label}</div>
                    )
                },
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    if (RoleModulesList[dataIndex] != null) {
                        return (
                            <Checkbox
                                checked={RoleModulesList[dataIndex].IsAccess == "1" ? true : false}
                                onChange={e => {
                                    let newModuleAccessData = [...RoleModulesList]
                                    // newModuleAccessData[dataIndex].IsAccess = e.target.checked ? "1" : "0"
                                    newModuleAccessData[dataIndex] = Object.assign({}, newModuleAccessData[dataIndex], { IsAccess: e.target.checked ? "1" : "0" })
                                    setRoleModulesList(newModuleAccessData)
                                }}
                                name="Access"
                                color="primary"
                            />
                        )
                    }
                }
            }
        },
        {
            name: "Action",
            label: "Action",
            options: {
                filter: false,
                sort: false,
                customHeadLabelRender: (columnMeta) => {
                    return (
                        <div style={{ fontSize: "12px" }} >{columnMeta.name}</div>
                    )
                },
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    if (RoleModulesList[dataIndex] != null)
                        return (
                            <Link
                                to={RoleModulesList[dataIndex].IsAccess == "1" ? "/UserScreen" : "#"}
                                onClick={() => {
                                    dispatch(setModuleId({ ModuleId: RoleModulesList[dataIndex].ModuleId, ModuleName: RoleModulesList[dataIndex].ModuleName }))
                                }}
                            >
                                <AccessibilityNew />
                            </Link>
                        );
                }
            }
        },
    ];

    const handleSubmit = async () => {
        setBtnLoader(true)
        const response = await dispatch(save_role_modules(RoleModulesList))

        toast.success(response.payload.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });

        setBtnLoader(false)
        onLoad();
    }

    return ReactDom.createPortal(

        <div className="col-md-12">
            {/* <ToastContainer /> */}
            <div class="card" >
                <div class="card-body">
                    <div className='row' style={{ padding: '1%' }} >
                        <div className="col-md-6 col-lg-6 col-sm-6">
                            <h2>{Role.RoleName}</h2>
                        </div>
                        <div className="col-md-6 col-lg-6 col-sm-6" style={{ textAlign: 'right' }} >
                            <button
                                type="button"
                                onClick={handleSubmit}
                                disabled={btnLoader}
                                className="btn btn-primary waves-effect height-of-button"
                            > <Save />{btnLoader ? "Saving..." : "Save"} </button> &ensp;
                            <Link to={"/UserAccessList"} id="cancel" className="btn btn-primary waves-effect height-of-button" ><Cancel /> Close </Link>
                        </div>
                    </div>
                    <MuiThemeProvider theme={getMuiTheme()}>
                        <MUIDataTable
                            style={{ size: "200px" }}
                            data={RoleModulesList}
                            columns={columns}
                            options={{
                                selectableRows: false,
                                search: false,
                                download: false,
                                print: false,
                                viewColumns: false,
                                filter: false,
                                selectableRows: false,
                                selection: true,
                                toolbar: false,
                                paging: false,
                                headerStyle: {
                                    fontSize: 12
                                },
                            }
                            }
                        />
                    </MuiThemeProvider>

                    <GlobalLoader
                        open={loading}
                    />
                </div>
            </div>
        </div>

        , document.getElementById('content')
    )
}



export default UserAccess