import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { Login } from "../../Services/Login"



export const loggedIn = createAsyncThunk('login/loggedIn',async ({username, password})=>{
    let body ={
        UserLogin: username,
        Password: password
    }
    const responseBody = await Login(body)
    
    return responseBody.data;
})

const initialState = {
    loading: false,
    user: [],
    ModuleAccess: [],
    error:'',
    UserCompany:[]
}
const loginSlice = createSlice({
    name:'login',
    initialState,
    reducers:{
        userLoggedOut:(state,action) => {
            state = initialState
            localStorage.removeItem('token')
        },
    },
    extraReducers:{
        [loggedIn.pending]: state => {
            state.loading = true
        },
        [loggedIn.fulfilled]:(state,action) => {
            state.loading = false
            state.user = action.payload.user || [];
            state.ModuleAccess = action.payload.role_modules || [];
            state.error = ''
            state.UserCompany = action.payload.companies || []
        },
        [loggedIn.rejected]:(state,action) => {
            state.loading = false
            state.user = [];
            state.error = action.error.message
            state.UserCompany =  []
        },
    }
})

export default loginSlice.reducer

export const { userLoggedOut } = loginSlice.actions;