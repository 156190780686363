import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { getRoleModules, getRoleScreens, getRolesList, getUser, getUsersList, saveRole, saveRoleModules, saveRoleScreens, saveUser } from "../../Services/Security"

export const get_Role_List = createAsyncThunk('Security/get_Role_List', async (params, { dispatch, getState }) => {
    const responseBody = await getRolesList()
    return responseBody.data;
})

export const get_role_modules = createAsyncThunk('Security/get_role_modules', async (RoleId, { dispatch, getState }) => {
    const responseBody = await getRoleModules(RoleId)
    return responseBody.data;
})

export const save_role_modules = createAsyncThunk('Security/save_role_modules', async (data, { dispatch, getState }) => {
    const responseBody = await saveRoleModules(data)
    return responseBody.data;
})

export const get_role_screens = createAsyncThunk('Security/get_role_screens', async (data, { dispatch, getState }) => {
    const responseBody = await getRoleScreens(data)
    return responseBody.data;
})

export const save_role_screens = createAsyncThunk('Security/save_role_screens', async (data, { dispatch, getState }) => {
    const responseBody = await saveRoleScreens(data)
    return responseBody.data;
})

export const save_role = createAsyncThunk('Security/save_role', async (data, { dispatch, getState }) => {
    const responseBody = await saveRole(data)
    return responseBody.data;
})

export const get_users_list = createAsyncThunk('Security/get_users_list', async (data, { dispatch, getState }) => {
    const responseBody = await getUsersList()
    return responseBody.data;
})

export const get_user = createAsyncThunk('Security/get_user', async (UserId, { dispatch, getState }) => {
    const responseBody = await getUser(UserId)
    return responseBody.data;
})

export const save_user = createAsyncThunk('Security/save_user', async (data, { dispatch, getState }) => {
    const responseBody = await saveUser(data)
    return responseBody.data;
})


const initialState = {
    userRolesList: [],
    RoleModulesList: [],
    UserScreenList: [],
    UserList: [],
    User: [],
    loading: false,
    Module: {
        ModuleId: null,
        ModuleName: ''
    },
    Role: {
        RoleId: null,
        RoleName: ''
    },
    error: '',
    GlobalFormName: "Dashboard"
}

const SecuritySlice = createSlice({
    name: 'Security',
    initialState,
    reducers: {
        RoleName: (state, action) => {
            state.Role.RoleName = action.payload.RoleName;
            state.Role.RoleId = action.payload.RoleId;
        },
        setModuleId: (state, action) => {
            state.Module.ModuleId = action.payload.ModuleId;
            state.Module.ModuleName = action.payload.ModuleName;
        },
        setGlobalFormName: (state, action) => {
            state.GlobalFormName = action.payload;
        }
    },
    extraReducers: {
        [get_Role_List.pending]: (state, action) => {
            state.loading = true;
        },
        [get_Role_List.fulfilled]: (state, action) => {
            action.payload.roles_list.map((role, index) => {
                role.rno = index + 1;
            })
            state.userRolesList = action.payload.roles_list;
            state.loading = false;
        },
        [get_role_modules.pending]: (state, action) => {
            state.loading = true;
        },
        [get_role_modules.fulfilled]: (state, action) => {
            action.payload.role_modules.map((role, index) => {
                role.rno = index + 1;
            })
            state.RoleModulesList = action.payload.role_modules;
            state.loading = false;
        },
        [get_role_screens.pending]: (state, action) => {
            state.loading = true;
        },
        [get_role_screens.fulfilled]: (state, action) => {
            action.payload.role_screens.map(screen => {
                screen.open = false;
            })
            state.UserScreenList = action.payload.role_screens;
            state.loading = false;
        },
        [get_users_list.pending]: (state, action) => {
            state.loading = true;
        },
        [get_users_list.fulfilled]: (state, action) => {
            action.payload.users_list.map((user, index) => {
                user.rno = index + 1
            })
            state.UserList = action.payload.users_list;
            state.loading = false;
        },
        [get_user.pending]: (state, action) => {
            state.loading = true;
        },
        [get_user.fulfilled]: (state, action) => {
            state.User = action.payload.User;
            state.loading = false;
        },

    }
})

export default SecuritySlice.reducer

export const { RoleName, setModuleId, setGlobalFormName } = SecuritySlice.actions;