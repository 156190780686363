import Sidebar from "./components/layout/Sidebar";
import Topbar from "./components/layout/Topbar";
import Login from "./components/Login/Login";
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import ProtectedRoutes from "./ProtectedRoutes";
import UnshippedOrderList from "./components/Unshipped_Order/UnshippedOrderList";
import Dashboard from "./components/Dashboard/Dashboard";
import "./App.css"
import { Provider } from 'react-redux';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import createRootReducer from "./store/rootReducer";
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { PersistGate } from 'redux-persist/integration/react';
import UserAccessList from "./components/Security/UserAccessList";
import UserAccess from "./components/Security/UserAccess";
import UserScreen from "./components/Security/UserScreen";
import UserList from "./components/Security/UserList";
import Groups from "./components/Groups/Groups";
import ItemCat from "./components/ItemCategory/ItemCat";
import Party from "./components/Parties/Parties";
import ItemsList from "./components/Item/ItemsList";
import PurchaseSale from "./components/PurchaseSale/PurchaseSale"
import PurchaseInvoice from "./components/PurchaseSale/PurchaseInvoice"
import InvoiceReportPrint from "./components/PurchaseSale/InvoiceReportPrint"
import Reports from "./components/Reports/Reports"
import StockMov_Report from "./components/Reports/StockMov_Report"
import ItemsReport from "./components/Item/ItemsReport";
import ItemsListReport from "./components/Reports/ItemsListReport"
import PurchaseSale_Report from "./components/Reports/PurchaseSale_Report"
import PartyWisePurSale_Report from "./components/Reports/PartyWisePurSale_Report";
import BufferStockReport from "./components/Reports/BufferStockReport";
import ItemWisePurSale_Report from "./components/Reports/ItemWisePurSale_Report";

const rootReducer = createRootReducer();


const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
});

let persistor = persistStore(store);
function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <Topbar />
          <Sidebar />
          <Switch>
            <Route exact path="/" component={Login} />
            <ProtectedRoutes component={UnshippedOrderList} exact path="/UnshippedOrder" />
            <ProtectedRoutes component={Dashboard} exact path="/Dashboard" />
            <ProtectedRoutes component={UserAccessList} exact path="/UserAccessList" />
            <ProtectedRoutes component={UserAccess} exact path="/UserAccess" />
            <ProtectedRoutes component={UserScreen} exact path="/UserScreen" />
            <ProtectedRoutes component={UserList} exact path="/UserList" />
            <ProtectedRoutes component={Groups} exact path="/GroupsList" />
            <ProtectedRoutes component={ItemCat} exact path="/ItemCatList" />
            <ProtectedRoutes component={Party} exact path="/PartiesList" />
            <ProtectedRoutes component={ItemsList} exact path="/ItemsList" />
            <ProtectedRoutes component={PurchaseSale} exact path="/PurchaseSale" />
            <ProtectedRoutes component={PurchaseInvoice} exact path="/PurchaseInvoice" />
            <ProtectedRoutes component={InvoiceReportPrint} exact path="/InvoiceReportPrint" />
            <ProtectedRoutes component={Reports} exact path="/Reports" />
            <ProtectedRoutes component={StockMov_Report} exact path="/StockMov_Report" />
            <ProtectedRoutes component={ItemsReport} exact path="/ItemsReport" />
            <ProtectedRoutes component={ItemsListReport} exact path="/ItemsListReport" />
            <ProtectedRoutes component={PurchaseSale_Report} exact path="/PurchaseSale_Report" />
            <ProtectedRoutes component={PartyWisePurSale_Report} exact path="/PartyWisePurSale_Report" />
            <ProtectedRoutes component={BufferStockReport} exact path="/BufferStockReport" />
            <ProtectedRoutes component={ItemWisePurSale_Report} exact path="/ItemWisePurSale_Report" />

          </Switch>
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;

// export const store = configureStore({
//   reducer: rootReducer,
//   middleware: (getDefaultMiddleware) =>
//     getDefaultMiddleware({
//       immutableCheck: false,
//       serializableCheck: false,

//     })
// })
