import {REACT_BASE_URL} from "./Common"
import axios from "axios"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const Login = async(body) => {
    const response = await axios.post(`${REACT_BASE_URL}/User/Login`,body)
    return response;
}

export const checkPassword = async(body) => {
    const response = await axios.post(`${REACT_BASE_URL}/User/checkPassword`,body)
    return response;
}

export const updatePassword = async(body) => {
    const response = await axios.post(`${REACT_BASE_URL}/User/updatePassword`,body)
    return response;
}