import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { SelectGroups, SaveGroup } from '../../Services/GroupsAPI'


export const SelectGroupData = createAsyncThunk('group/SelectGroupData', async (CompID) => {
    const response = await SelectGroups(CompID)
    return response.data
})

const initialState = {
    loading: false,
    GroupData: []
}

const groupSlice = createSlice({
    name: 'group',
    initialState,
    reducers: {},
    extraReducers: {
        [SelectGroupData.fulfilled]: (state, action) => {
            state.GroupData = action.payload.Data
        }
    }
})

export default groupSlice.reducer;