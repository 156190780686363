import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TextField, FormControlLabel, Checkbox, Card } from '@material-ui/core'
import Autocomplete from '@mui/material/Autocomplete';
import { Save, Close } from "@material-ui/icons";
import { SaveItemCat } from '../../Services/ItemCatAPI'
import { useSelector, useDispatch } from "react-redux"
import LoadingButton from '@mui/lab/LoadingButton'
import SaveAltIcon from '@mui/icons-material/SaveAlt';

const ItemCatModal = (props) => {
    const { open, handleClose, catid, fetchData, name } = props;
    const CompID = useSelector(({ login }) => login.UserCompany[0]?.CompID)
    const [CatName, setCatName] = useState('')
    const [ErrorMsg, setErrorMsg] = useState({ CatNameError: '' })
    const [Loading, setLoading] = useState(false)

    useEffect(() => {
        if (open) {
            setCatName('')
            if (catid > 0) {
                setCatName(name)
            }
        }
    }, [open])

    const validate = () => {
        if (!CatName.trim()) {
            setErrorMsg({ ...ErrorMsg, CatNameError: 'Name Required' })
            document.getElementById('CatName').focus()
            return false;
        }
        setErrorMsg({ CatNameError: '' })
        return true;
    }

    const handleSubmit = async () => {

        if (validate()) {
            const ItemCatData = {
                CatID: catid,
                CompID: CompID,
                CatName: CatName,
            }
            const isAddNew = catid > 0 ? false : true
            setLoading(true)
            const response = await SaveItemCat(ItemCatData, isAddNew)
            console.log(response)
            if (response.status === 200) {
                toast.success(response.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                setLoading(false)
                handleClose()
                fetchData()
            }
        }

    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title" className="ThemeHeader">
                {
                    catid == 0 ? (
                        "Add Item Category"
                    ) : (
                        "Edit Item Category"
                    )
                }
            </DialogTitle>
            <DialogContent>
                {/* <DialogContentText id="alert-dialog-description"> */}
                <TextField
                    name="CatName"
                    id="CatName"
                    label="Category Name"
                    value={CatName}
                    autoFocus
                    onChange={e => {
                        setCatName(e.target.value)
                        setErrorMsg({ ...ErrorMsg, CatNameError: '' })
                    }}
                    fullWidth

                />
                <span style={{ color: "red" }}>{ErrorMsg.CatNameError}</span>


            </DialogContent>
            <DialogActions>
                <LoadingButton
                    style={{ color: "white", backgroundColor: "#293042", height: "30px" }}
                    className="ThemeHeader popup-button"
                    size="small"
                    color="secondary"
                    onClick={handleClose}
                    // loading={Loading}
                    // loadingPosition="start"
                    startIcon={<Close style={{ fontSize: '22px', marginLeft: "10px" }} />}
                    variant="contained"
                />
                <LoadingButton
                    style={{ color: "white", backgroundColor: "#293042", height: "30px" }}
                    className="ThemeHeader popup-button"
                    size="small"
                    color="secondary"
                    onClick={handleSubmit}
                    loading={Loading}
                    loadingPosition="start"
                    startIcon={<SaveAltIcon style={{ fontSize: '22px', marginLeft: "10px" }} />}
                    variant="contained"
                />
                {/* <button className="ThemeHeader popup-button" onClick={handleClose}><Close /></button>
                <button className="ThemeHeader popup-button" onClick={handleSubmit} autoFocus>
                    <Save />
                </button> */}
            </DialogActions>
        </Dialog>
    )
}

export default ItemCatModal