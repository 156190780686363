import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux'
import { setTransactionData } from "../PurchaseSale/TransactionSlice"
import { setGlobalFormName } from "../Security/UsersSlice"
import PasswordModal from './PasswordModal'
import './LiveButton.css'

function Sidebar() {
  const dispatch = useDispatch();
  const _ModuleAccess = useSelector(({ login }) => login.ModuleAccess)
  const Comp_Abbr = useSelector(({ login }) => login?.UserCompany[0]?.Abbr)
  const Comp_Name = useSelector(({ login }) => login?.UserCompany[0]?.CompName)
  const [isHovered, setIsHovered] = useState(false);
  const [Open, setOpen] = useState(false)
  const [Modules, setModules] = useState({
    RedCarpetMaintenance: true,
    FederationItemFile: true,
    Vibenet: true,
    Finance: true,
    Security: true
  })
  const [Screens, setScreens] = useState({
    AmazonListings: true,
    UpdateHPEligibleSKUs: true,
    UpdateProductType: true,
    MapPricing: true,
    ProductCharges: true,
    UnshippedOrders: true,
    ReturnOrders: true,
    OrderMaintenance: true,
    Tracking: true,
    AmazonQuotes: true
  })
  const showModal = () => {
    setOpen(true)
  }
  const closeModal = () => {
    setOpen(false)
  }
  // useEffect(() => {

  //   if (_ModuleAccess.length > 0) {

  //     let _mMudules = {
  //       RedCarpetMaintenance: false,
  //       FederationItemFile: false,
  //       Vibenet: false,
  //       Finance: false,
  //       Security: false
  //     }
  //     let _mScreens = {
  //       AmazonListings: false,
  //       UpdateHPEligibleSKUs: false,
  //       UpdateProductType: false,
  //       MapPricing: false,
  //       ProductCharges: false,
  //       UnshippedOrders: false,
  //       ReturnOrders: false,
  //       OrderMaintenance: false,
  //       Tracking: false,
  //       AmazonQuotes: false
  //     }

  //     _ModuleAccess.map((Module, ModuleIndex) => {

  //       if (Module.ModuleId == "1") {
  //         if (Module.IsAccess == "1") {
  //           _mMudules.RedCarpetMaintenance = true;
  //         }
  //       } else if (Module.ModuleId == "2") {
  //         if (Module.IsAccess == "1") {
  //           _mMudules.FederationItemFile = true;
  //         }
  //       } else if (Module.ModuleId == "3") {
  //         if (Module.IsAccess == "1") {
  //           _mMudules.Vibenet = true;
  //         }
  //       } else if (Module.ModuleId == "4") {
  //         if (Module.IsAccess == "1") {
  //           _mMudules.Finance = true;
  //         }
  //       } else if (Module.ModuleId == "5") {
  //         if (Module.IsAccess == "1") {
  //           _mMudules.Security = true;
  //         }
  //       }

  //       if (Module.RoduleScreens) {
  //         Module.RoduleScreens.map((Screen, ScreenIndex) => {

  //           if (Screen.ScreenId == "1") {
  //             if (Screen.IsAccess == "1") {
  //               _mScreens.AmazonListings = true;
  //             }
  //           } else if (Screen.ScreenId == "2") {
  //             if (Screen.IsAccess == "1") {
  //               _mScreens.UpdateHPEligibleSKUs = true;
  //             }
  //           } else if (Screen.ScreenId == "3") {
  //             if (Screen.IsAccess == "1") {
  //               _mScreens.UpdateProductType = true;
  //             }
  //           } else if (Screen.ScreenId == "4") {
  //             if (Screen.IsAccess == "1") {
  //               _mScreens.MapPricing = true;
  //             }
  //           } else if (Screen.ScreenId == "5") {
  //             if (Screen.IsAccess == "1") {
  //               _mScreens.ProductCharges = true;
  //             }
  //           } else if (Screen.ScreenId == "6") {
  //             if (Screen.IsAccess == "1") {
  //               _mScreens.UnshippedOrders = true;
  //             }
  //           } else if (Screen.ScreenId == "7") {
  //             if (Screen.IsAccess == "1") {
  //               _mScreens.ReturnOrders = true;
  //             }
  //           } else if (Screen.ScreenId == "8") {
  //             if (Screen.IsAccess == "1") {
  //               _mScreens.OrderMaintenance = true;
  //             }
  //           } else if (Screen.ScreenId == "9") {
  //             if (Screen.IsAccess == "1") {
  //               _mScreens.Tracking = true;
  //             }
  //           } else if (Screen.ScreenId == "10") {
  //             if (Screen.IsAccess == "1") {
  //               _mScreens.AmazonQuotes = true;
  //             }
  //           }
  //         })
  //       }
  //     })

  //     setModules(_mMudules)
  //     setScreens(_mScreens)

  //   }

  // }, [_ModuleAccess])

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return ReactDOM.createPortal(
    <>
      <a className="sidebar-brand" href="/Dashboard">
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="20px" height="20px" viewBox="0 0 20 20" enableBackground="new 0 0 20 20" xmlSpace="preserve">
          <path d="M19.4,4.1l-9-4C10.1,0,9.9,0,9.6,0.1l-9,4C0.2,4.2,0,4.6,0,5s0.2,0.8,0.6,0.9l9,4C9.7,10,9.9,10,10,10s0.3,0,0.4-0.1l9-4
          C19.8,5.8,20,5.4,20,5S19.8,4.2,19.4,4.1z" />
          <path d="M10,15c-0.1,0-0.3,0-0.4-0.1l-9-4c-0.5-0.2-0.7-0.8-0.5-1.3c0.2-0.5,0.8-0.7,1.3-0.5l8.6,3.8l8.6-3.8c0.5-0.2,1.1,0,1.3,0.5
          c0.2,0.5,0,1.1-0.5,1.3l-9,4C10.3,15,10.1,15,10,15z" />
          <path d="M10,20c-0.1,0-0.3,0-0.4-0.1l-9-4c-0.5-0.2-0.7-0.8-0.5-1.3c0.2-0.5,0.8-0.7,1.3-0.5l8.6,3.8l8.6-3.8c0.5-0.2,1.1,0,1.3,0.5
          c0.2,0.5,0,1.1-0.5,1.3l-9,4C10.3,20,10.1,20,10,20z" />
        </svg>
        {/* <span className="align-middle me-3" style={{ hover: { Comp_Name } }}> {Comp_Abbr} </span> */}
        {/* <span
          className="align-middle me-3"
          style={{
            position: 'relative',
            cursor: 'pointer',
          }}
          onMouseEnter={() => handleMouseEnter()}
          onMouseLeave={() => handleMouseLeave()}
        >
          {Comp_Abbr}
          {isHovered && (
            <span
              style={{
                position: 'absolute',
                top: '100%',
                left: '50%',
                transform: 'translateX(-50%)',
                backgroundColor: 'white',
                color: 'black',
                padding: '0.5rem',
                borderRadius: '4px',
              }}
            >
              {Comp_Name}
            </span>
          )}
        </span> */}
        <span
          className="align-middle me-3"
          style={{
            position: 'relative',
            cursor: 'pointer',
            whiteSpace: 'nowrap', // Added this line
          }}
          onMouseEnter={() => handleMouseEnter()}
          onMouseLeave={() => handleMouseLeave()}
        >
          {Comp_Abbr}
          {isHovered && (
            <span
              style={{
                position: 'absolute',
                top: '110%',
                left: '50%',
                transform: 'translateX(-50%)',
                backgroundColor: 'white',
                color: 'black',
                padding: '0.5rem',
                borderRadius: '4px',
                fontSize: "13px"
              }}
            >
              {Comp_Name}
            </span>
          )}

        </span>




      </a>
      <ul className="sidebar-nav">

        <li className="sidebar-item">
          <Link onClick={e => {
            dispatch(setGlobalFormName('Dashboard'))
          }} data-bs-target="#dashboards" to={"/Dashboard"} className="sidebar-link collapsed">
            <i className="align-middle" data-feather="info" /> <span style={{ color: "white" }} className="align-middle">Dashboards</span>
            {/* <span className="badge badge-sidebar-primary">5</span> */}
          </Link>
        </li>
        {/* {
          Modules.RedCarpetMaintenance && (
            <li className="sidebar-item">
              <a data-bs-target="#multi" data-bs-toggle="collapse" className="sidebar-link collapsed">
                <i className="align-middle" data-feather="folder" /> <span style={{ color: "white" }} className="align-middle">Red Carpet Maintenance</span>
              </a>
              <ul id="multi" className="sidebar-dropdown list-unstyled collapse" data-bs-parent="#sidebar">
                <li className="sidebar-item">
                  <a data-bs-target="#multi-2" data-bs-toggle="collapse" className="sidebar-link collapsed">
                    Listings
                  </a>
                  {
                    Screens.AmazonListings && (
                      <ul id="multi-2" className="sidebar-dropdown list-unstyled collapse">
                        <li className="sidebar-item">
                          <a className="sidebar-link" data-bs-target="#">Amazon Listings</a>
                        </li>
                      </ul>
                    )
                  }

                </li>
                <li className="sidebar-item">
                  <a data-bs-target="#multi-4" data-bs-toggle="collapse" className="sidebar-link collapsed">
                    Database Management
                  </a>
                  <ul id="multi-4" className="sidebar-dropdown list-unstyled collapse">

                    {
                      Screens.UpdateHPEligibleSKUs && (
                        <li className="sidebar-item">
                          <a className="sidebar-link" data-bs-target="#">Update HP Eligible SKUs</a>
                        </li>
                      )
                    }
                    {
                      Screens.UpdateProductType && (
                        <li className="sidebar-item">
                          <a className="sidebar-link" data-bs-target="#">Update Product Type</a>
                        </li>
                      )
                    }

                    {
                      Screens.MapPricing && (
                        <li className="sidebar-item">
                          <a className="sidebar-link" data-bs-target="#">MAP Pricing</a>
                        </li>
                      )
                    }
                    {
                      Screens.ProductCharges && (
                        <li className="sidebar-item">
                          <a className="sidebar-link" data-bs-target="#">Product Charges</a>
                        </li>
                      )
                    }
                  </ul>
                </li>
                <li className="sidebar-item">
                  <a data-bs-target="#multi-3" data-bs-toggle="collapse" className="sidebar-link collapsed">
                    Order Management
                  </a>
                  <ul id="multi-3" className="sidebar-dropdown list-unstyled collapse">

                    {
                      Screens.UnshippedOrders && (
                        <li className="sidebar-item">
                          <Link className="sidebar-link" to={"/UnshippedOrder"}>Unshipped Orders</Link>
                        </li>
                      )
                    }
                    {
                      Screens.ReturnOrders && (
                        <li className="sidebar-item">
                          <a className="sidebar-link" data-bs-target="#">Return Orders</a>
                        </li>
                      )
                    }
                    {
                      Screens.OrderMaintenance && (
                        <li className="sidebar-item">
                          <a className="sidebar-link" data-bs-target="#">Order Maintenance</a>
                        </li>
                      )
                    }
                    {
                      Screens.Tracking && (
                        <li className="sidebar-item">
                          <a className="sidebar-link" data-bs-target="#">Trackings</a>
                        </li>
                      )
                    }
                    {
                      Screens.AmazonQuotes && (
                        <li className="sidebar-item">
                          <a className="sidebar-link" data-bs-target="#">Amazon Quotes</a>
                        </li>
                      )
                    }
                  </ul>
                </li>
              </ul>
            </li>
          )
        } */}

        {
          Modules.FederationItemFile && (
            <li className="sidebar-item">
              <a data-bs-target="#documentation" data-bs-toggle="collapse" className="sidebar-link collapsed">
                <i className="align-middle" data-feather="folder" /> <span style={{ color: "white" }} className="align-middle">Setup</span>
              </a>
              <ul id="documentation" className="sidebar-dropdown list-unstyled collapse " data-bs-parent="#sidebar">

                <li className="sidebar-item"><Link onClick={e => {
                  dispatch(setGlobalFormName('Groups'))
                }} style={{ color: "white" }} className="sidebar-link" to={"/GroupsList"} >Groups</Link></li>
                <li className="sidebar-item"><Link onClick={e => {
                  dispatch(setGlobalFormName('Parties'))
                }} style={{ color: "white" }} className="sidebar-link" to={"/PartiesList"} >Parties</Link></li>
                <li className="sidebar-item"><Link onClick={e => {
                  dispatch(setGlobalFormName('Item Category'))
                }} style={{ color: "white" }} className="sidebar-link" to={"/ItemCatList"} >Item Category</Link></li>
                <li className="sidebar-item"><Link onClick={e => {
                  dispatch(setGlobalFormName('Items'))
                }} style={{ color: "white" }} className="sidebar-link" to={"/ItemsList"} >Items</Link></li>
              </ul>
            </li>
          )
        }

        {
          Modules.Vibenet && (
            <li className="sidebar-item">
              <a data-bs-target="#Vibenet" data-bs-toggle="collapse" className="sidebar-link collapsed">
                <i className="align-middle" data-feather="folder" /> <span style={{ color: "white" }} className="align-middle">Transaction</span>
              </a>
              <ul id="Vibenet" className="sidebar-dropdown list-unstyled collapse " data-bs-parent="#sidebar">
                <li className="sidebar-item"><Link style={{ color: "white" }} className="sidebar-link" to={"/PurchaseSale"}
                  onClick={e => {
                    const obj = { StockType: "Stock In", FormName: "Purchase" }
                    dispatch(setTransactionData(obj))
                    dispatch(setGlobalFormName('Purchase'))
                  }}
                >Purchase</Link></li>
                <li className="sidebar-item"><Link style={{ color: "white" }} className="sidebar-link" to={"/PurchaseSale"}
                  onClick={e => {
                    const obj = { StockType: "Stock Out", FormName: "Consumption" }
                    dispatch(setTransactionData(obj))
                    dispatch(setGlobalFormName('Consumption'))
                  }}
                >Consumption</Link></li>
              </ul>
            </li>
          )
        }

        {
          Modules.Finance && (
            <li className="sidebar-item">
              <a data-bs-target="#Finance" data-bs-toggle="collapse" className="sidebar-link collapsed">
                <i className="align-middle" data-feather="folder" /> <span style={{ color: "white" }} className="align-middle">Reports</span>
              </a>
              <ul id="Finance" className="sidebar-dropdown list-unstyled collapse " data-bs-parent="#sidebar">
                <li className="sidebar-item"><Link onClick={e => {
                  dispatch(setGlobalFormName('Reports'))
                }} style={{ color: "white" }} className="sidebar-link" to={"/Reports"} >Reports</Link></li>
              </ul>
            </li>
          )
        }
        <div>
          <p className="popup-passwordmodal" style={{ textAlign: "center", marginTop: "8%" }}>
            <Link
              onClick={e => {
                showModal();
              }}
            >Change Password</Link>
          </p>
        </div>
        <div>
          <button style={{ textAlign: "center"}} className="live-button">
            <span className="dot"></span>
            Live
          </button>
        </div>
        {/* {
          Modules.Security && (
            <li className="sidebar-item">
              <a data-bs-target="#security" data-bs-toggle="collapse" className="sidebar-link collapsed">
                <i className="align-middle" data-feather="lock" /> <span style={{ color: "white" }} className="align-middle">Security</span>
              </a>
              <ul id="security" className="sidebar-dropdown list-unstyled collapse " data-bs-parent="#sidebar">
                <li className="sidebar-item">
                  <Link className="sidebar-link" to={'/UserAccessList'} >User Access</Link>
                </li>
                <li className="sidebar-item">
                  <Link className="sidebar-link" to={"/UserList"} >Users</Link>
                </li>
              </ul>
            </li>
          )
        } */}

        {/* <li className="sidebar-item">
            <a data-bs-target="#Help" data-bs-toggle="collapse" className="sidebar-link collapsed">
              <i className="align-middle" data-feather="help-circle" /> <span className="align-middle">Help</span>
             
            </a>
          </li> */}



      </ul>
      <PasswordModal
        handleClose={closeModal}
        open={Open}
      // GroupID={GroupID}
      // fetchData={fetchData}
      // type={Group_Type}
      // name={Group_Name}
      />

    </>, document.getElementById('sidebar_menu')
  )
}

export default Sidebar