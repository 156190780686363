import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TextField, FormControlLabel, Checkbox, Card } from '@material-ui/core'
import Autocomplete from '@mui/material/Autocomplete';
import { Save, Close } from "@material-ui/icons";
import { checkPassword, updatePassword } from '../../Services/Login'
import { useSelector, useDispatch } from "react-redux"

const PasswordModal = (props) => {
    const { open, handleClose } = props;
    const CompID = useSelector(({ login }) => login.UserCompany[0]?.CompID)
    const UserID = useSelector(({ login }) => login?.user[0]?.UserID)
    const [currentPassword, setCurrentPassword] = useState('')
    const [passwordMatch, setPasswordMatch] = useState(false)
    const [newPassword, setNewPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [passwordMatchNew, setPasswordMatchNew] = useState(true);
    const [ErrorMsg, setErrorMsg] = useState({ CurrentPassError: '', NewPassError: '', ConfirmPassError: '' })

    useEffect(() => {
    }, [open])

    const handleChangePassword = async (currentPassword) => {
        try {
            const body = { currentPassword, UserID }
            const response = await checkPassword(body)
            const { passwordMatch } = response.data;
            setPasswordMatch(passwordMatch);
            if (!passwordMatch) {
                setErrorMsg({ ...ErrorMsg, CurrentPassError: 'Enter a valid current password' });
            } else {
                setErrorMsg({ ...ErrorMsg, CurrentPassError: '' });
            }
        } catch (error) {
            console.error(error);
        }
    }
    const validate = () => {
        // if (!currentPassword.trim()) {
        //     setErrorMsg({ ...ErrorMsg, CurrentPassError: 'Password Required' })
        //     document.getElementById('GroupName').focus()
        //     return false;
        // }
        if (!newPassword) {
            setErrorMsg({ CurrentPassError: '', NewPassError: 'New Password Required' })
            document.getElementById('disable-clearable').focus()
            return false;
        }
        if (!confirmPassword) {
            setErrorMsg({ CurrentPassError: '', NewPassError: '', ConfirmPassError: 'New Password Required' })
            document.getElementById('disable-clearable').focus()
            return false;
        }
        setErrorMsg({ CurrentPassError: '', NewPassError: '', ConfirmPassError: '' })
        return true;
    }

    const ClearField = () => {
        setPasswordMatch(false)
        setCurrentPassword('')
        setNewPassword('')
        setConfirmPassword('')
        setPasswordMatchNew(true)
        handleClose()
    }

    const handleSave = async () => {
        if (validate()) {
            if (newPassword === confirmPassword) {
                try {
                    const response = await updatePassword({
                        NewPassword: newPassword,
                        UserID
                    })

                    if (response.status === 200) {
                        toast.success(response.data.message, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                        ClearField()
                    }
                } catch (error) {
                    console.error(error);
                    setPasswordMatchNew(false);
                }
            } else {
                setPasswordMatchNew(false);
            }
        }

    }


    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title" className="ThemeHeader">
                Change Password
            </DialogTitle>
            <DialogContent style={{ width: '400px' }}>
                {/* <DialogContentText id="alert-dialog-description"> */}
                <TextField
                    type="password"
                    name="currentPassword"
                    id="currentPassword"
                    label="Enter Current Password"
                    value={currentPassword}
                    autoFocus
                    onChange={(e) => {
                        setCurrentPassword(e.target.value)
                        handleChangePassword(e.target.value)
                        // setErrorMsg({ ...ErrorMsg, CurrentPassError: '' })
                    }}
                    fullWidth
                />
                {/* {!passwordMatch && <p>Wrong Current Password</p>} */}

                <span style={{ color: "red" }}>{ErrorMsg.CurrentPassError}</span>

                <TextField
                    type="password"
                    name="newPassword"
                    id="newPassword"
                    label="Enter New Password"
                    value={newPassword}
                    disabled={!passwordMatch}
                    onChange={(e) => {
                        setNewPassword(e.target.value)
                        setErrorMsg({ ...ErrorMsg, NewPassError: '' })
                    }}
                    fullWidth
                />
                <span style={{ color: "red" }}>{ErrorMsg.NewPassError}</span>
                <TextField
                    type="password"
                    name="confirmPassword"
                    id="confirmPassword"
                    label="Enter Confirm Password"
                    value={confirmPassword}
                    disabled={!passwordMatch}
                    onChange={(e) => {
                        setConfirmPassword(e.target.value)
                        setErrorMsg({ ...ErrorMsg, ConfirmPassError: '' })
                    }}
                    fullWidth
                />
                <span style={{ color: "red" }}>{ErrorMsg.ConfirmPassError}</span>
            </DialogContent>
            <DialogActions>
                {!passwordMatchNew && <p style={{ color: "red", marginRight: "30%" }}>Passwords do not match</p>}
                <button className="ThemeHeader popup-button" onClick={handleClose}><Close /></button>
                <button className="ThemeHeader popup-button" onClick={handleSave} disabled={!passwordMatch} >
                    <Save />
                </button>
            </DialogActions>
        </Dialog >
    )
}

export default PasswordModal