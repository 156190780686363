import React, { useEffect, useState } from 'react'
import ReactDom from 'react-dom'
import MUIDataTable from 'mui-datatables'
import { Link } from "react-router-dom";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { Edit, AccessibilityNew, Add, Delete } from "@material-ui/icons";
import { useSelector, useDispatch } from "react-redux"
import GlobalLoader from "../layout/GlobalLoader"
import { getMuiTheme } from '../../Services/Common'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import GroupModal from './GroupModal'
import { SelectGroupData } from './GroupsSlices'
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import { DeleteGroup } from "../../Services/GroupsAPI";


function Groups() {
    const dispatch = useDispatch()
    const _GroupsList = useSelector(({ GroupsSlice }) => GroupsSlice.GroupData)
    const CompID = useSelector(({ login }) => login.UserCompany[0]?.CompID)
    const UserType = useSelector(({ login }) => login?.user[0]?.UserType)
    document.title = "Stock Keeper / Groups"
    const [loading, setLoading] = useState(false)
    const [Open, setOpen] = useState(false)
    const [GroupID, setGroupID] = useState(0)
    const [Group_Name, setGroup_Name] = useState('')
    const [Group_Type, setGroup_Type] = useState('')
    useEffect(() => {
        setLoading(true)
        fetchData()
    }, [])
    const showModal = () => {
        setOpen(true)
    }
    const closeModal = () => {
        setOpen(false)
    }
    async function fetchData() {
        const response = await dispatch(SelectGroupData(CompID))
        setLoading(false)

    }
    const handleDelete = (GroupID) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to recover this data!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Delete This Group!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                const response = await DeleteGroup(GroupID)
                if (response.status === 200) {
                    if (response.data.status) {
                        Swal.fire(

                            'Deleted!',
                            'Your Group has been deleted.',
                            'success'
                        );
                        fetchData();
                    }
                    else {
                        Swal.fire(

                            'OOPPSS!',
                            response.data.message,
                            'info'
                        );
                        fetchData();
                    }

                }

            }
        });
    };
    const columns = [
        {
            name: "row_number",
            label: "#",
            options: {
                filter: true,
                sort: true,
                customHeadLabelRender: (columnMeta) => {
                    return (
                        <div style={{ fontSize: "12px" }} >{columnMeta.label}</div>
                    )
                },
            }
        },
        {
            name: "GroupName",
            label: "Group Name",
            options: {
                filter: true,
                sort: true,
                customHeadLabelRender: (columnMeta) => {
                    return (
                        <div style={{ fontSize: "12px" }} >{columnMeta.label}</div>
                    )
                },
            }
        },
        {
            name: "GroupType",
            label: "Type",
            options: {
                filter: true,
                sort: true,
                customHeadLabelRender: (columnMeta) => {
                    return (
                        <div style={{ fontSize: "12px" }} >{columnMeta.label}</div>
                    )
                },
            }
        },
        {
            name: "Action",
            label: "Action",
            options: {
                filter: false,
                sort: false,
                customHeadLabelRender: (columnMeta) => {
                    return (
                        <div style={{ fontSize: "12px" }} >{columnMeta.name}</div>
                    )
                },
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    if (_GroupsList[dataIndex] != null)
                        return (
                            <>
                                {UserType === 'Admin' ? (
                                    <Link
                                        to={"#"}
                                        onClick={e => {
                                            setGroupID(_GroupsList[dataIndex].GroupID)
                                            setGroup_Type(_GroupsList[dataIndex].GroupType)
                                            setGroup_Name(_GroupsList[dataIndex].GroupName)
                                            //     handleClickOpen()
                                            showModal()
                                        }}
                                    >
                                        <Edit className="IconsColor popup-button" />
                                    </Link>
                                ) : (
                                    <Link
                                        to={"#"}
                                        onClick={e => {
                                            Swal.fire(
                                                'OOPS!',
                                                `Only Admin can Edit This Group!`,
                                                'Info'
                                            )
                                        }}
                                    >
                                        <Edit className="IconsColor popup-button" />
                                    </Link>
                                )
                                }

                                &ensp;
                                {UserType === 'Admin' ? (
                                    <Link
                                        to={"#"}
                                        onClick={e => {
                                            handleDelete(_GroupsList[dataIndex].GroupID)
                                        }}
                                    >
                                        <Delete className="IconsColor popup-button" />
                                    </Link>
                                ) : (
                                    <Link
                                        to={"#"}
                                        onClick={e => {
                                            Swal.fire(
                                                'OOPS!',
                                                `Only Admin can Delete this Group!`,
                                                'Info'
                                            )
                                        }}
                                    >
                                        <Delete className="IconsColor popup-button" />
                                    </Link>
                                )
                                }
                            </>
                        );
                }
            }
        },
    ];

    return ReactDom.createPortal(
        <div className="col-md-12">
            <ToastContainer />
            <div class="card" >
                <div class="card-body">
                    <div className='row' style={{ padding: '1%' }} >
                        <div className="col-md-6 col-lg-6 col-sm-6">
                            <h1>Groups</h1>
                        </div>
                        <div className="col-md-6 col-lg-6 col-sm-6" style={{ textAlign: 'right' }}>
                            {UserType === 'Admin' ? (
                                <button
                                    type="button"
                                    onClick={e => {
                                        setGroupID(0);
                                        showModal();
                                    }}
                                    className="ThemeHeader popup-button"
                                >
                                    <Add />
                                </button>
                            ) : (
                                <button
                                    type="button"
                                    onClick={() =>
                                        Swal.fire(
                                            'OOPS!',
                                            `Only Admin can Add New Group!`,
                                            'Info'
                                        )
                                    }
                                    className="ThemeHeader popup-button"
                                >
                                    <Add />
                                </button>
                            )}
                        </div>
                        {/* {
                            UserType === "Admin" &&
                            <div className="col-md-6 col-lg-6 col-sm-6" style={{ textAlign: 'right' }} >
                                <button
                                    type="button"
                                    onClick={e => {
                                        setGroupID(0)
                                        showModal()
                                    }}
                                    className="ThemeHeader popup-button"
                                > <Add /> </button>
                            </div>
                        } */}

                    </div>
                    <MuiThemeProvider theme={getMuiTheme()}>
                        <MUIDataTable
                            style={{ size: "200px" }}
                            data={_GroupsList}
                            columns={columns}
                            options={{
                                selectableRows: false,
                                search: false,
                                download: false,
                                print: false,
                                viewColumns: false,
                                filter: false,
                                selectableRows: false,
                                selection: true,
                                toolbar: false,
                                paging: false,
                                headerStyle: {
                                    fontSize: 12
                                },
                            }
                            }
                        />
                    </MuiThemeProvider>

                    <GlobalLoader
                        open={loading}
                    />
                    <GroupModal
                        handleClose={closeModal}
                        open={Open}
                        GroupID={GroupID}
                        fetchData={fetchData}
                        type={Group_Type}
                        name={Group_Name}
                    />
                </div>
            </div>
        </div>
        ,
        document.getElementById('content')
    )
}

export default Groups