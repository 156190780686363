import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { SelectParty } from '../../Services/PartiesAPI'


export const SelectPartyData = createAsyncThunk('party/SelectPartyData', async (params) => {
    const { CompID, GroupID } = params
    const response = await SelectParty(CompID, GroupID)
    return response.data
})

const initialState = {
    loading: false,
    PartyData: []
}

const PartySlice = createSlice({
    name: 'party',
    initialState,
    reducers: {},
    extraReducers: {
        [SelectPartyData.fulfilled]: (state, action) => {
            state.PartyData = action.payload.Data
        }
    }
})

export default PartySlice.reducer;