import React from 'react'
import { Watch } from 'react-loader-spinner'
import { Modal, Box } from "@material-ui/core"

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    boxShadow: 24,
    p: 4,
    border: 'none'
};

function GlobalLoader(props) {
    const { open } = props;



    return (
        <div>
            <Modal
                open={open}
                // onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div style={{ marginLeft: '50%', marginTop: '50%' }}>
                        <Watch
                            height={80}
                            width={80}
                            color="#293042"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                            ariaLabel='oval-loading'
                            secondaryColor="#293042"
                            strokeWidth={2}
                            strokeWidthSecondary={2}
                        />
                    </div>
                </Box>
            </Modal>
        </div>
    )
}

export default GlobalLoader