import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"


const initialState = {
    loading: false,
    TransactionData: [],
    StockType: "",
    FormName: "",
    AddNew: true,
    TrID: 0,
    TrData: null,
    FilterData: null,
}

const TransactionSlice = createSlice({
    name: 'transaction',
    initialState,
    reducers: {
        setTransactionData: (state, action) => {
            state.StockType = action.payload.StockType
            state.FormName = action.payload.FormName
        },
        setFormData: (state, action) => {
            state.AddNew = action.payload.AddNew
            state.TrID = action.payload.TrID
            state.TrData = action.payload.TrData
            state.FilterData = action.payload.FilterData
        },
    },
})

export default TransactionSlice.reducer;

export const { setTransactionData, setFormData } = TransactionSlice.actions;
