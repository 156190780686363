import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TextField, Checkbox, Card } from '@material-ui/core'
import Autocomplete from '@mui/material/Autocomplete';
import { Save, Close } from "@material-ui/icons";
import { SaveParty } from '../../Services/PartiesAPI'
import { Row, Col } from "react-bootstrap"
import { useSelector, useDispatch } from "react-redux"
import LoadingButton from '@mui/lab/LoadingButton'
import SaveAltIcon from '@mui/icons-material/SaveAlt';

const PartyModal = (props) => {
    const { open, handleClose, PartyID, fetchData, GroupID, SelectedGroup, reload, setReload, partyname, phno, email, addr, isactive } = props;
    const CompID = useSelector(({ login }) => login.UserCompany[0]?.CompID)
    const [PhoneNo, setPhoneNo] = useState('')
    const [Address, setAddress] = useState('')
    const [Email, setEmail] = useState('')
    const [PartyName, setPartyName] = useState('')
    const [IsActive, setIsActive] = useState(true)
    const [ErrorMsg, setErrorMsg] = useState({ NameError: '', PhError: '', EmailError: '', AddressError: '' })
    const [Loading, setLoading] = useState(false)

    useEffect(() => {
        if (open) {
            setPartyName('')
            setPhoneNo('')
            setEmail('')
            setAddress('')
            if (PartyID > 0) {
                setPartyName(partyname)
                setPhoneNo(phno)
                setEmail(email)
                setAddress(addr)
                setIsActive(isactive)
            }
        }
    }, [open])

    const validate = () => {
        if (!PartyName.trim()) {
            setErrorMsg({ ...ErrorMsg, NameError: 'Name Required' })
            document.getElementById('PartyName').focus()
            return false;
        }
        // if (!PhoneNo) {
        //     setErrorMsg({ NameError: '', PhError: 'Ph Required' })
        //     document.getElementById('disable-clearable').focus()
        //     return false;
        // }
        // if (!Email) {
        //     setErrorMsg({ NameError: '', PhError: '', EmailError: 'Email Required' })
        //     document.getElementById('disable-clearable').focus()
        //     return false;
        // }
        if (!Address) {
            setErrorMsg({ NameError: '', PhError: '', EmailError: '', AddressError: 'Address Required' })
            document.getElementById('disable-clearable').focus()
            return false;
        }
        setErrorMsg({ NameError: '', PhError: '', EmailError: '', AddressError: '' })
        return true;
    }

    const handleSubmit = async () => {

        if (validate()) {
            const PartyData = {
                PartyID: PartyID,
                CompID: CompID,
                GroupID: SelectedGroup?.GroupID,
                PartyName: PartyName,
                PhoneNo: PhoneNo,
                Address: Address,
                Email: Email,
                IsActive: IsActive ? 1 : 0
            }
            const isAddNew = PartyID > 0 ? false : true
            setLoading(true)
            const response = await SaveParty(PartyData, isAddNew)
            console.log(response)
            if (response.status === 200) {
                toast.success(response.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                setLoading(false)
                handleClose()
                fetchData(SelectedGroup?.GroupID)
            }
        }

    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title" className="ThemeHeader">
                {
                    PartyID == 0 ? (
                        `Add ${SelectedGroup?.GroupName}`
                    ) : (
                        "Edit Party"
                    )
                }
            </DialogTitle>
            <DialogContent>
                <TextField
                    name="PartyName"
                    id="PartyName"
                    label="Name"
                    value={PartyName}
                    autoFocus
                    onChange={e => {
                        setPartyName(e.target.value)
                        setErrorMsg({ ...ErrorMsg, NameError: '' })
                    }}
                    fullWidth

                />
                <span style={{ color: "red" }}>{ErrorMsg.NameError}</span>
                <Row>
                    <Col sm={6} md={6} xl={6} lg={6}>
                        <TextField
                            name="PhoneNo"
                            id="PhoneNo"
                            label="Phone #"
                            value={PhoneNo}
                            autoFocus
                            onChange={e => {
                                setPhoneNo(e.target.value)
                                // setErrorMsg({ ...ErrorMsg, PhError: '' })
                            }}
                            fullWidth

                        />
                        {/* <span style={{ color: "red" }}>{ErrorMsg.PhError}</span> */}
                    </Col>
                    <Col sm={6} md={6} xl={6} lg={6}>
                        <TextField
                            name="Email"
                            id="Email"
                            label="Email"
                            value={Email}
                            autoFocus
                            onChange={e => {
                                setEmail(e.target.value)
                                // setErrorMsg({ ...ErrorMsg, EmailError: '' })
                            }}
                            fullWidth

                        />
                        {/* <span style={{ color: "red" }}>{ErrorMsg.EmailError}</span> */}
                    </Col>
                </Row>
                <TextField
                    name="Address"
                    id="Address"
                    label="Address"
                    value={Address}
                    autoFocus
                    onChange={e => {
                        setAddress(e.target.value)
                        setErrorMsg({ ...ErrorMsg, AddressError: '' })
                    }}
                    fullWidth

                />
                <span style={{ color: "red" }}>{ErrorMsg.AddressError}</span>
            </DialogContent>
            <FormGroup style={{ marginLeft: "22px" }}>
                <FormControlLabel control={<Checkbox checked={IsActive}
                    onChange={e => {
                        setIsActive(e.target.checked)
                    }}
                    style={{ color: "#293042" }}
                />} label="Active" />
            </FormGroup>
            <DialogActions>
            <LoadingButton
                    style={{ color: "white", backgroundColor: "#293042", height: "30px" }}
                    className="ThemeHeader popup-button"
                    size="small"
                    color="secondary"
                    onClick={handleClose}
                    // loading={Loading}
                    // loadingPosition="start"
                    startIcon={<Close style={{ fontSize: '22px', marginLeft: "10px" }} />}
                    variant="contained"
                />
                <LoadingButton
                    style={{ color: "white", backgroundColor: "#293042", height: "30px" }}
                    className="ThemeHeader popup-button"
                    size="small"
                    color="secondary"
                    onClick={handleSubmit}
                    loading={Loading}
                    loadingPosition="start"
                    startIcon={<SaveAltIcon style={{ fontSize: '22px', marginLeft: "10px" }} />}
                    variant="contained"
                />
                {/* <button className="ThemeHeader popup-button" onClick={handleClose}><Close /></button>
                <button className="ThemeHeader popup-button" onClick={handleSubmit} autoFocus>
                    <Save />
                </button> */}
            </DialogActions>
        </Dialog>
    )
}

export default PartyModal