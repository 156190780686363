import { REACT_BASE_URL } from "./Common"
import axios from "axios"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export const SelectItems = async (CompID, CatID, IsActive) => {
    const response = await axios.get(`${REACT_BASE_URL}/Items/SelectItems/${CompID}/${CatID}/${IsActive}`)
    return response;
}

export const SelectItemGridEdit = async (CompID,ItemID) => {
    const response = await axios.get(`${REACT_BASE_URL}/Items/SelectItemGridEdit/${CompID}/${ItemID}`)
    return response;
}
export const BufferStock_Report = async (CompID, CatID) => {
    const response = await axios.get(`${REACT_BASE_URL}/Items/BufferStock_Report/${CompID}/${CatID}`)
    return response;
}

export const SaveItem = async (ItemData, IsAddNew) => {
    const response = await axios.post(`${REACT_BASE_URL}/Items/SaveItem`, { ItemData, IsAddNew })
    return response;
}