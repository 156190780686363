import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap';
import { connect } from "react-redux"
import { hide_divs, getCurrentDate } from "./../../Services/Common"
import ReactDom from "react-dom"
import { useSelector, useDispatch } from "react-redux"
import { ItemWisePurchaseSaleReport } from "../../Services/TransactionAPI"

// function setPageSize(cssPageSize) {

//     const style = document.createElement('style');
//     style.innerHTML = `@page {

//     margin-top: 2%;
//     margin-bottom: 2%;

// };
//     `;
//     style.id = 'page-orientation';
//     document.head.appendChild(style);
// }


function ItemWisePurSale_Report() {
    const CompName = useSelector(({ login }) => login.UserCompany[0]?.CompName)
    const CompID = useSelector(({ login }) => login.UserCompany[0]?.CompID)
    const UserName = useSelector(({ login }) => login?.user[0]?.UserName)
    const [FromDate_, setFromDate_] = useState("")
    const [ToDate_, setToDate_] = useState("")
    const [TrType, setTrType] = useState("")
    const [ItemName, setItemName] = useState("")
    const [Item_Data, setItem_Data] = useState([])

    useEffect(() => {
        hide_divs()
        var _ItemWisePurSale_ReportData = JSON.parse(localStorage.getItem("ItemWisePurSale_ReportData"))
        let _TrType
        if (_ItemWisePurSale_ReportData.Modal_Title === "Item Wise Purchases") {
            setTrType("Stock In")
            _TrType = "Stock In"
        }
        else {
            setTrType("Stock Out")
            _TrType = "Stock Out"

        }
        setItemName(_ItemWisePurSale_ReportData.SelectedItem.ItemName)
        setFromDate_(_ItemWisePurSale_ReportData.From_Date)
        setToDate_(_ItemWisePurSale_ReportData.To_Date)
        fetchAPIData(_ItemWisePurSale_ReportData.SelectedItem.ItemID, _ItemWisePurSale_ReportData.From_Date, _ItemWisePurSale_ReportData.To_Date, _TrType)
        // localStorage.removeItem("ItemWisePurSale_ReportData")
        // setPageSize('Portrait')
    }, [])

    async function fetchAPIData(ItemID, FromDate, ToDate, _TrType) {
        debugger
        const response = await ItemWisePurchaseSaleReport(CompID, ItemID, _TrType, FromDate, ToDate)
        setItem_Data(response.data.Data)
        // setLoading(false)
    }
    return ReactDom.createPortal(
        <Row className='report-font' style={{ fontSize: '18px' }}>
            <Col>
                <div  >
                    <Row sm={12} md={12} xl={12} style={{ textAlign: 'center', color: "black", marginTop: '3%' }}>
                        <Col sm={12} md={12} xl={12}>
                            <h1 style={{ fontWeight: "bold" }}> {CompName} </h1>
                            {TrType == "Stock In" ? "Item Wise Purchase Report" : "Item Wise Consumption Report"}
                        </Col>
                    </Row>
                    <Row sm={12} md={12} xl={12} style={{ display: 'flex', alignItems: 'center', marginLeft: '1.5%', marginTop: '2%' }}>
                        <Col sm={3} md={3} xl={3} lg={3}>
                            From Date:
                        </Col>
                        <Col sm={3} md={3} xl={3} lg={3}>
                            {FromDate_}
                        </Col>
                        <Col sm={3} md={3} xl={3} lg={3}>
                            Item Name:
                        </Col>
                        <Col sm={3} md={3} xl={3} lg={3}>
                            {ItemName}
                        </Col>
                    </Row>
                    <Row sm={12} md={12} xl={12} style={{ display: 'flex', alignItems: 'center', marginLeft: '1.5%' }}>
                        <Col sm={3} md={3} xl={3} lg={3}>
                            To Date:
                        </Col>
                        <Col sm={3} md={3} xl={3} lg={3}>
                            {ToDate_}
                        </Col>
                    </Row>
                    <Row className='row-margin-left row-margin-right' style={{ marginTop: '2%' }}>
                        <Col>
                            <div id='table_div' className="row-margin-top" >
                                <table id='report_table' className='report_table' width="95%" style={{ marginLeft: "2%" }}>
                                    <thead id="thead" style={{ borderBottom: '2px solid black', borderTop: '2px solid black', color: "black" }}>
                                        <tr style={{ border: "none" }}>
                                            <th style={{ width: '20%', textAlign: 'left', fontWeight: "bold" }} >Item Name</th>
                                            <th style={{ width: '20%', textAlign: 'left', fontWeight: "bold" }} >{TrType == "Stock In" ? "Purchase Date" : "Installation Date"}</th>
                                            <th style={{ width: '30%', textAlign: 'left', fontWeight: "bold" }} >{TrType == "Stock In" ? "Party Name" : "Department Name"}</th>
                                            <th style={{ width: '20%', textAlign: 'center', fontWeight: "bold" }} >Quantity</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            Item_Data.map((value, index) => {
                                                if (Item_Data[index - 1]) {
                                                    if (value.ItemName === Item_Data[index - 1].ItemName) {
                                                        return (
                                                            <tr>
                                                                <td></td>
                                                                <td style={{ textAlign: 'left' }}>{value.TrDate}</td>
                                                                <td style={{ textAlign: 'left' }}>{value.PartyName}</td>
                                                                <td style={{ textAlign: 'center' }}>{value.TotalQuantity}</td>
                                                            </tr>
                                                        );
                                                    }
                                                    else {
                                                        return (
                                                            <>
                                                                <tr style={{ borderTop: "1px solid black" }}>
                                                                    <td>
                                                                        <h3 style={{ marginLeft: "4%" }}>
                                                                            <u><b>{value.ItemName}</b></u>
                                                                        </h3>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td></td>
                                                                    <td style={{ textAlign: 'left' }}>{value.TrDate}</td>
                                                                    <td style={{ textAlign: 'left' }}>{value.PartyName}</td>
                                                                    <td style={{ textAlign: 'center' }}>{value.TotalQuantity}</td>
                                                                </tr>
                                                            </>
                                                        );
                                                    }
                                                }
                                                else {
                                                    return (
                                                        <>
                                                            <tr>
                                                                <td>
                                                                    <h3 style={{ marginLeft: "4%" }}>
                                                                        <u><b>{value.ItemName}</b></u>
                                                                    </h3>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td></td>
                                                                <td style={{ textAlign: 'left' }}>{value.TrDate}</td>
                                                                <td style={{ textAlign: 'left' }}>{value.PartyName}</td>
                                                                <td style={{ textAlign: 'center' }}>{value.TotalQuantity}</td>
                                                            </tr>
                                                        </>
                                                    );
                                                }
                                            })
                                        }


                                    </tbody>
                                    <tr style={{ borderBottom: '1px solid black' }}>
                                    </tr>
                                </table>
                                <div >

                                    <div style={{ marginTop: '5%', marginLeft: "1.5%" }}>
                                        &nbsp;

                                        Username: {UserName}   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                                        Date: {`${getCurrentDate()}`}
                                    </div>

                                </div>


                            </div>
                        </Col>
                    </Row>
                </div>
            </Col>

        </Row>, document.getElementById('body')
    )
}

export default ItemWisePurSale_Report