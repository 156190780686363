import React, { useEffect, useState } from "react";
import { Route, withRouter } from "react-router-dom";
// import { connect } from 'react-redux'

const ProtectedRoutes = ({ currentToken, NavigateToLogin, component: Component, ...rest }) => {


  return (
    <Route
      {...rest}
      render={(props) => {
        let token = null
        const _localStorageToken = localStorage.getItem("token");
        if (_localStorageToken) {
          token = _localStorageToken
          // localStorage.removeItem('token')
          // sessionStorage.setItem('token', _localStorageToken)
        }
        // else {
        //   token = sessionStorage.getItem('token')
        // }
        if (token) {

          // var _exp = localStorage.getItem('exp')
          // if (Date.now() >= _exp * 1000) {
          return <Component {...props} />;
          // }
          // else {
          //   props.history.push("/");
          // }

        }
        else {
          props.history.push("/");

        }
      }}
    />
  );
};


// const mapStateToProps =  state =>({
//   currentToken: state.UserLogin.loginToken,
//   NavigateToLogin: state.RELOAD_FORM.status
// })
// export default connect(mapStateToProps)(withRouter(ProtectedRoutes));

export default withRouter(ProtectedRoutes)
