import React, { useEffect, useState } from 'react'
import ReactDom from 'react-dom'
import MUIDataTable from 'mui-datatables'
import { Link } from "react-router-dom";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { Edit, AccessibilityNew, Add, Delete } from "@material-ui/icons";
import { useSelector, useDispatch } from "react-redux"
import GlobalLoader from "../layout/GlobalLoader"
import { getMuiTheme } from '../../Services/Common'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ItemCatModal from './ItemCatModal'
import { SelectItemCatData } from './ItemCatSlices'
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import { DeleteItemCat } from "../../Services/ItemCatAPI";


function ItemCat() {
    const dispatch = useDispatch()
    const _ItemCatList = useSelector(({ ItemCatSlices }) => ItemCatSlices.ItemCatData)
    const CompID = useSelector(({ login }) => login.UserCompany[0]?.CompID)
    const UserType = useSelector(({ login }) => login?.user[0]?.UserType)
    document.title = "Stock Keeper / Item Category"
    const [loading, setLoading] = useState(false)
    const [Open, setOpen] = useState(false)
    const [CatID, setCatID] = useState(0)
    const [Cat_Name, setCat_Name] = useState('')
    // const [Group_Type, setGroup_Type] = useState('')
    useEffect(() => {
        setLoading(true)
        fetchData()
    }, [])
    const showModal = () => {
        setOpen(true)
    }
    const closeModal = () => {
        setOpen(false)
    }
    async function fetchData() {
        const response = await dispatch(SelectItemCatData(CompID))
        setLoading(false)

    }
    const handleDelete = (CatID) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to recover this data!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Delete This Item Cat!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                const response = await DeleteItemCat(CatID)
                if (response.status === 200) {
                    if (response.data.status) {
                        Swal.fire(

                            'Deleted!',
                            'Your ItemCat has been deleted.',
                            'success'
                        );
                        fetchData();
                    }
                    else {
                        Swal.fire(

                            'OOPPSS!',
                            response.data.message,
                            'info'
                        );
                        fetchData();
                    }

                }

            }
        });
    };

    const columns = [
        {
            name: "row_number",
            label: "#",
            options: {
                filter: true,
                sort: true,
                customHeadLabelRender: (columnMeta) => {
                    return (
                        <div style={{ fontSize: "12px" }} >{columnMeta.label}</div>
                    )
                },
            }
        },
        {
            name: "CatName",
            label: "Category Name",
            options: {
                filter: true,
                sort: true,
                customHeadLabelRender: (columnMeta) => {
                    return (
                        <div style={{ fontSize: "12px" }} >{columnMeta.label}</div>
                    )
                },
            }
        },
        {
            name: "Action",
            label: "Action",
            options: {
                filter: false,
                sort: false,
                customHeadLabelRender: (columnMeta) => {
                    return (
                        <div style={{ fontSize: "12px" }} >{columnMeta.name}</div>
                    )
                },
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    if (_ItemCatList[dataIndex] != null)
                        return (
                            <>
                                {UserType === 'Admin' ? (
                                    <Link
                                        to={"#"}
                                        onClick={e => {
                                            setCatID(_ItemCatList[dataIndex].CatID)
                                            setCat_Name(_ItemCatList[dataIndex].CatName)
                                            //     handleClickOpen()
                                            showModal()
                                        }}
                                    >
                                        <Edit className="IconsColor popup-button" />
                                    </Link>
                                ) : (
                                    <Link
                                        to={"#"}
                                        onClick={e => {
                                            Swal.fire(
                                                'OOPS!',
                                                `Only Admin can Edit This Category!`,
                                                'Info'
                                            )
                                        }}
                                    >
                                        <Edit className="IconsColor popup-button" />
                                    </Link>
                                )
                                }
                                &ensp;
                                {UserType === 'Admin' ? (
                                    <Link
                                        to={"#"}
                                        onClick={e => {
                                            handleDelete(_ItemCatList[dataIndex].CatID)
                                        }}
                                    >
                                        <Delete className="IconsColor popup-button" />
                                    </Link>
                                ) : (
                                    <Link
                                        to={"#"}
                                        onClick={e => {
                                            Swal.fire(
                                                'OOPS!',
                                                `Only Admin can Delete this Item Category!`,
                                                'Info'
                                            )
                                        }}
                                    >
                                        <Delete className="IconsColor popup-button" />
                                    </Link>
                                )
                                }
                            </>
                        );
                }
            }
        },
    ];

    return ReactDom.createPortal(
        <div className="col-md-12">
            <ToastContainer />
            <div class="card" >
                <div class="card-body">
                    <div className='row' style={{ padding: '1%' }} >
                        <div className="col-md-6 col-lg-6 col-sm-6">
                            <h1>Items Category</h1>
                        </div>
                        <div className="col-md-6 col-lg-6 col-sm-6" style={{ textAlign: 'right' }} >
                            {UserType === 'Admin' ? (
                                <button
                                    // style={{ backgroundColor:"#293042" }}
                                    type="button"
                                    onClick={e => {
                                        setCatID(0)
                                        showModal()
                                    }}
                                    className="ThemeHeader popup-button"
                                > <Add /> </button>
                            ) : (
                                <button
                                    // style={{ backgroundColor:"#293042" }}
                                    type="button"
                                    onClick={e => {
                                        Swal.fire(
                                            'OOPS!',
                                            `Only Admin can Add Item Category!`,
                                            'Info'
                                        )
                                    }}
                                    className="ThemeHeader popup-button"
                                > <Add /> </button>
                            )}
                        </div>


                    </div>
                    <MuiThemeProvider theme={getMuiTheme()}>
                        <MUIDataTable
                            style={{ size: "200px" }}
                            data={_ItemCatList}
                            columns={columns}
                            options={{
                                selectableRows: false,
                                search: true,
                                download: false,
                                print: false,
                                viewColumns: false,
                                filter: false,
                                selectableRows: false,
                                selection: true,
                                toolbar: false,
                                paging: false,
                                headerStyle: {
                                    fontSize: 12
                                },
                            }
                            }
                        />
                    </MuiThemeProvider>

                    <GlobalLoader
                        open={loading}
                    />
                    <ItemCatModal
                        handleClose={closeModal}
                        open={Open}
                        catid={CatID}
                        fetchData={fetchData}
                        name={Cat_Name}
                    />
                </div>
            </div>
        </div>
        ,
        document.getElementById('content')
    )
}

export default ItemCat