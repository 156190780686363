import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TextField, FormControlLabel, Checkbox, Card } from '@material-ui/core'
import Autocomplete from '@mui/material/Autocomplete';
import { Save, Close } from "@material-ui/icons";
import { SaveGroup } from '../../Services/GroupsAPI'
import { useSelector, useDispatch } from "react-redux"
import LoadingButton from '@mui/lab/LoadingButton'
import SaveAltIcon from '@mui/icons-material/SaveAlt';

const GroupModal = (props) => {
    const { open, handleClose, GroupID, fetchData, type, name, reload, setReload } = props;
    const CompID = useSelector(({ login }) => login.UserCompany[0]?.CompID)
    const [GroupName, setGroupName] = useState('')
    const [SelectedGroupType, setSelectedGroupType] = useState(null)
    const [ErrorMsg, setErrorMsg] = useState({ NameError: '', TypeError: '' })
    const [Loading, setLoading] = useState(false)
    useEffect(() => {
        if (open) {
            setGroupName('')
            setSelectedGroupType(null)
            if (GroupID > 0) {
                const _opt = TypeOptions.find(x => x.title === type)
                setSelectedGroupType(_opt)
                setGroupName(name)
            }
        }
    }, [open])

    const TypeOptions = [
        { title: 'Stock In' },
        { title: 'Stock Out' },
    ]
    const validate = () => {
        if (!GroupName.trim()) {
            setErrorMsg({ ...ErrorMsg, NameError: 'Name Required' })
            document.getElementById('GroupName').focus()
            return false;
        }
        if (!SelectedGroupType) {
            setErrorMsg({ NameError: '', TypeError: 'Type Required' })
            document.getElementById('disable-clearable').focus()
            return false;
        }
        setErrorMsg({ NameError: '', TypeError: '' })
        return true;
    }

    const handleSubmit = async () => {

        if (validate()) {
            const GroupData = {
                GroupID: GroupID,
                CompID: CompID,
                GroupName: GroupName,
                GroupType: SelectedGroupType.title,
            }
            const isAddNew = GroupID > 0 ? false : true
            setLoading(true)
            const response = await SaveGroup(GroupData, isAddNew)
            console.log(response)
            if (response.status === 200) {
                toast.success(response.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                setLoading(false)
                handleClose()
                fetchData()
            }
        }

    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title" className="ThemeHeader">
                {
                    GroupID == 0 ? (
                        "Add Group"
                    ) : (
                        "Edit Group"
                    )
                }
            </DialogTitle>
            <DialogContent>
                <TextField
                    name="GroupName"
                    id="GroupName"
                    label="Group Name"
                    value={GroupName}
                    autoFocus
                    onChange={e => {
                        setGroupName(e.target.value)
                        setErrorMsg({ ...ErrorMsg, NameError: '' })
                    }}
                    fullWidth

                />
                <span style={{ color: "red" }}>{ErrorMsg.NameError}</span>
            
                <Autocomplete
                    id="disable-clearable"
                    disableClearable
                    options={TypeOptions}
                    getOptionLabel={(option) => option.title}
                    value={SelectedGroupType}
                    onChange={(e, value) => {
                        setSelectedGroupType(value)
                        setErrorMsg({ ...ErrorMsg, TypeError: '' })
                    }}
                    renderInput={(params) => (
                        <TextField {...params} label="Type" variant="standard" />
                    )}
                    fullWidth
                />
                <span style={{ color: "red" }}>{ErrorMsg.TypeError}</span>
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    style={{ color: "white", backgroundColor: "#293042", height: "30px" }}
                    className="ThemeHeader popup-button"
                    size="small"
                    color="secondary"
                    onClick={handleClose}
                    startIcon={<Close style={{ fontSize: '22px', marginLeft: "10px" }} />}
                    variant="contained"
                />
                <LoadingButton
                    style={{ color: "white", backgroundColor: "#293042", height: "30px" }}
                    className="ThemeHeader popup-button"
                    size="small"
                    color="secondary"
                    onClick={handleSubmit}
                    loading={Loading}
                    loadingPosition="start"
                    startIcon={<SaveAltIcon style={{ fontSize: '22px', marginLeft: "10px" }} />}
                    variant="contained"
                />
            </DialogActions>
        </Dialog>
    )
}

export default GroupModal