import React from 'react'
// import MUIDataTable from 'mui-datatables'
// import { MuiThemeProvider } from "@material-ui/core/styles";
// import { getMuiTheme, getCurrentDate } from '../../Services/Common'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Edit, Delete } from "@material-ui/icons";


function Grid({ ItemDetailData, EditAction, DeleteAction }) {
    return (
        <div class="col-md-12 col-lg-12 col-sm-12">
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ fontWeight: 'bold' }}>Sr #</TableCell>
                            <TableCell align="center" style={{ fontWeight: 'bold' }}>Item Name</TableCell>
                            <TableCell align="center" style={{ fontWeight: 'bold' }}>Qty</TableCell>
                            <TableCell align="center" style={{ fontWeight: 'bold' }}>Rate</TableCell>
                            <TableCell align="center" style={{ fontWeight: 'bold' }}>Amount</TableCell>
                            <TableCell align="center" style={{ fontWeight: 'bold' }}>Actions</TableCell>
                        </TableRow>
                    </TableHead>

                    {/* <TableBody>
                        {ItemDetailData.map((row, rowIndex) => (
                            <TableRow
                                key={row.ItemName}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                style={{ height: '20px' }}
                            >
                                <TableCell component="th" scope="row">
                                    {row.SrNo}
                                </TableCell>
                                <TableCell align="center">{row.ItemName}</TableCell>
                                <TableCell align="center">{row.Qty}</TableCell>
                                <TableCell align="center">{row.Rate}</TableCell>
                                <TableCell align="center">{row.Amount}</TableCell>
                                <TableCell align="center">
                                    <Edit onClick={(e) => EditAction(rowIndex)} /> &ensp;{' '}
                                    <Delete onClick={(e) => DeleteAction(rowIndex)} />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody> */}


                    <TableBody>
                        {ItemDetailData.map((row, rowIndex) => (
                            <TableRow
                                key={row.ItemName}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    {row.SrNo}
                                </TableCell>
                                <TableCell align="center">{row.ItemName}</TableCell>
                                <TableCell align="center">{row.Qty}</TableCell>
                                <TableCell align="center">{row.Rate}</TableCell>
                                <TableCell align="center">{row.Amount}</TableCell>
                                <TableCell align="center"><Edit
                                    onClick={e => EditAction(rowIndex)}

                                /> &ensp; <Delete
                                        onClick={e => DeleteAction(rowIndex)}
                                    /></TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>

        //     <div className="col-md-12 col-lg-12 col-sm-12">
        //     <MuiThemeProvider theme={getMuiTheme()}>
        //         <MUIDataTable
        //             style={{ size: "200px" }}
        //             data={ItemDetailData}
        //             columns={columns}
        //             options={{
        //                 selectableRows: false,
        //                 search: false,
        //                 download: false,
        //                 print: false,
        //                 viewColumns: false,
        //                 filter: false,
        //                 selectableRows: false,
        //                 selection: true,
        //                 toolbar: false,
        //                 paging: false,
        //                 headerStyle: {
        //                     fontSize: 12
        //                 },
        //             }
        //             }
        //         />
        //     </MuiThemeProvider>
        // </div>
    )
}

export default Grid