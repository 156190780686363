import { REACT_BASE_URL } from "./Common"
import axios from "axios"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export const SelectGroups = async (CompID) => {
    const response = await axios.get(`${REACT_BASE_URL}/Groups/SelectGroups/${CompID}`)
    return response;
}

export const SaveGroup = async (GroupData, IsAddNew) => {
    const response = await axios.post(`${REACT_BASE_URL}/Groups/SaveGroup`, { GroupData, IsAddNew })
    return response;
}


export const DeleteGroup = async (GroupID) => {
    const response = await axios.delete(`${REACT_BASE_URL}/Groups/DeleteGroup/${GroupID}`)
    return response;
}

