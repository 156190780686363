import { REACT_BASE_URL } from "./Common"
import axios from "axios"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export const SelectItemCat = async (CompID) => {
    const response = await axios.get(`${REACT_BASE_URL}/ItemCategory/SelectItemCat/${CompID}`)
    return response;
}

export const SaveItemCat = async (ItemCatData, IsAddNew) => {
    const response = await axios.post(`${REACT_BASE_URL}/ItemCategory/SaveItemCat`, { ItemCatData, IsAddNew })
    return response;
}

export const DeleteItemCat = async (CatID) => {
    const response = await axios.delete(`${REACT_BASE_URL}/ItemCategory/DeleteItemCat/${CatID}`)
    return response;
}

